import React from 'react';
import {
	Container,
	Row,
	Col,
	ListGroupItemHeading,
	Button,
	Card,
	CardHeader,
	CardBody,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

	Collapse
} from 'reactstrap';
import { Form } from 'informed';
import { connect } from 'react-redux';
import { FiEdit } from 'react-icons/fi';
import {
	removeTrack,
	removeAllTracks,
	selectActiveTrack,
	selectActiveTheme,
	removeAllThemes,
	removeTheme,
	updateTrack,
	updateTheme,
	addThemes,
	addTracks
} from '../../redux/actionCreators';
import axios from 'axios';
import SearchInput from '../utilities/SearchInput';
import SearchTheme from '../utilities/SearchTheme';
import { WrappedText } from '../utilities/formElements';
import { required } from '../utilities/Validation';
import TrackDetail from './TrackDetail';
import ThemeDetail from './ThemeDetail';
import { TrackResults, ThemeResults } from './searchResults';



const mapStateToProps = state => ({
	activeTracks: state.activeTracks,
	activeThemes: state.activeThemes,
	activeTrack: state.activeTrack,
	activeTheme: state.activeTheme,
	playerState: state.playerState
});

const mapDispatchToProps = dispatch => ({
	removeTrack: (track) => dispatch(removeTrack(track)),
	removeAllTracks: () => dispatch(removeAllTracks()),
	removeAllThemes: () => dispatch(removeAllThemes()),
	removeTheme: theme => dispatch(removeTheme(theme)),
	selectActiveTrack: track => dispatch(selectActiveTrack(track)),
	selectActiveTheme: theme => dispatch(selectActiveTheme(theme)),
	updateTrack: track => dispatch(updateTrack(track)),
	updateTheme: theme => dispatch(updateTheme(theme)),
	addThemes: themes => dispatch(addThemes(themes)),
	addTracks: tracks => dispatch(addTracks(tracks)),
});

class Builder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {

			themeNameModal: {
				open: false
			},
			trackNameModal: {
				open: false
			},
			confirmModal: {
				open: false,
				onSubmit: () => null,
				header: ''
			},
			track: false,
			theme: false
		}

		this.isCancelled = false;

		this.playTrack = this.playTrack.bind(this);
		this.toggle = this.toggle.bind(this);
		this.removeThemeOfTrack = this.removeThemeOfTrack.bind(this);
		this.updateTheme = this.updateTheme.bind(this);
		this.focusActiveTrack = this.focusActiveTrack.bind(this);
		this.focusActiveTheme = this.focusActiveTheme.bind(this);
		this.deleteTheme = this.deleteTheme.bind(this);
		this.deleteTrack = this.deleteTrack.bind(this);
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	removeThemeOfTrack(id) {
		axios.get('/theme/remove-track/' + id + '/' + this.props.activeTrack._id)
			.then(res => {
				const themes = [];
				this.props.activeTrack.themes.forEach(theme => {
					if (theme._id !== id) {
						themes.push(theme);
					}

					this.props.selectActiveTrack({ ...this.props.activeTrack, themes: themes });

					if (this.props.activeThemes.find(el => el._id === id)) {
						this.props.updateTheme(res.data);
					}

					if (this.props.activeTheme._id === id) {
						this.props.selectActiveTheme(res.data);
					}
				});
			})
			.catch(err => window.console && console.log(err));
	}

	updateTheme(update) {
		axios.post('/theme/update', update)
			.then(res => {
				if (res.data.eligibleTracks.find(el => el._id === this.props.activeTrack._id) || (this.props.activeTrack.themes && this.props.activeTrack.themes.find(el => el._id === res.data._id))) {
					axios.get('/theme/of-track/' + this.props.activeTrack._id)
						.then(res2 => {
							this.props.selectActiveTrack({ ...this.props.activeTrack, themes: res2.data });
						})
						.catch(err => window.console && console.log(err));
				}

				this.props.updateTheme(res.data);
				if (this.props.activeTheme._id === res.data._id) {
					this.props.selectActiveTheme(res.data);
				}
			})
			.catch(err => window.console && console.log(err));
	}

	deleteTheme() {
		if (window.confirm('Are you sure you want to delete the "' + this.props.activeTheme.theme + '" theme?')) {

		const themeId = this.props.activeTheme._id;

		axios.delete('/theme/' + themeId)
			.then(() => {
				this.props.removeTheme(themeId);
				!this.isCancelled && this.setState({themeNameModal: {open: false}});
				this.props.selectActiveTheme({});
			})
			.catch(err => window.console && console.log(err));
		}
	}

	deleteTrack() {
		if (window.confirm('Are you sure you want to delete the "' + this.props.activeTrack.name + '" track?')) {

			const trackId = this.props.activeTrack._id;

			axios.delete('/track/' + trackId)
				.then(() => {
					this.props.removeTrack(trackId);
					!this.isCancelled && this.setState({trackNameModal: {open: false}});
					this.props.selectActiveTrack({});
				})
				.catch(err => window.console && console.log(err));
		}
	}

	playTrack(trackId = this.props.activeTrack.id) {
		axios.get('/spotify/select-track/' + trackId)
			.then(() => null)
			.catch(err => window.console && console.log(err));
	}

	pauseTrack() {
		axios.get('/spotify/play-pause')
			.then(() => null)
			.catch(err => window.console && console.log(err));
	}

	toggle(modal) {
		!this.iscancelled && this.setState(prevState => ({ ...prevState, [modal]: { ...prevState[modal], open: !prevState[modal].open } }))
	}

	focusActiveTrack(track) {
		axios.get('/theme/of-track/' + track._id)
			.then(res => {
				!this.isCancelled && this.props.selectActiveTrack({ ...track, themes: res.data });
				!this.isCancelled && this.setState(prevState => ({ ...prevState, track: true, theme: false }));
			})
			.catch(err => window.console && console.log(err));
	}

	focusActiveTheme(theme) {
		this.props.selectActiveTheme(theme);
		!this.isCancelled && this.setState(prevState => ({ ...prevState, track: false, theme: true }));
	}

	render() {
		return (
			<Container fluid className="p-3">
				<Row>
					<Col md='3'>
						<Row>
							<Col className="justify-content-start d-flex">
								<SearchInput focusActiveTrack={this.focusActiveTrack} playTrack={this.playTrack} pause={this.pauseTrack} />
							</Col>
						</Row>
						<ListGroupItemHeading className="pt-3">
							Workspace Tracks
						</ListGroupItemHeading>
						<TrackResults
							activeTracks={this.props.activeTracks}
							focusActiveTrack={this.focusActiveTrack}
							removeAllTracks={this.props.removeAllTracks}
							removeTrack={this.props.removeTrack}
							activeTheme={this.props.activeTheme}
						/>

					</Col>
					<Col md='6' className="scrolling-div" style={{ maxHeight: '87vh', overflow: 'auto' }}>
						{this.props.activeTrack._id &&
							<Card className="border-secondary m-2">
								<CardHeader className="bg-gradient-theme-left text-light" onClick={() => this.setState(prevState => ({ ...prevState, track: !prevState.track }))} style={{ cursor: 'pointer' }}>
									<Row>
										<Col xs={'auto'} className="p-0 d-flex align-items-center">
											<img src={this.props.activeTrack.albumCover} alt="album cover" height={50} />
										</Col>
										<Col>
											{this.props.activeTrack.name} <Button color="link" className="text-light" onClick={(e) => { this.setState({ trackNameModal: { open: true } }); e.stopPropagation() }} ><FiEdit /></Button><br />
											<small><em>{this.props.activeTrack.artist.map(el => el.name).join(', ')}</em></small>
										</Col>
										<Col xs="auto">Track</Col>
									</Row>
								</CardHeader>
								<Collapse isOpen={this.state.track}>
									<CardBody>
										<TrackDetail
											activeTrack={this.props.activeTrack}
											activeTheme={this.props.activeTheme}
											addThemes={this.props.addThemes}
											selectActiveTheme={this.props.selectActiveTheme}
											updateTrack={this.props.updateTrack}
											selectActiveTrack={this.props.selectActiveTrack}
											focusActiveTrack={this.focusActiveTrack}
											updateTheme={this.updateTheme}
											playTrack={this.playTrack}
											playerState={this.props.playerState}
										/>
									</CardBody>
								</Collapse>
							</Card>
						}
						{this.props.activeTheme._id &&
							<Card className="border-primary m-2">
								<CardHeader className="bg-gradient-theme-right text-light" onClick={() => this.setState(prevState => ({ ...prevState, theme: !prevState.theme }))} style={{ cursor: 'pointer' }}>
									<Row>
										<Col>
											<div>
												{this.props.activeTheme.theme} <Button color="link" className="text-light" onClick={(e) => { this.setState({ themeNameModal: { open: true } }); e.stopPropagation(); }} ><FiEdit /></Button>
											</div>
										</Col>
										<Col xs="auto">Theme</Col>
									</Row>
								</CardHeader>
								<Collapse isOpen={this.state.theme} >
									<CardBody>
										<ThemeDetail
											activeTheme={this.props.activeTheme}
											addTracks={this.props.addTracks}
											selectActiveTrack={this.props.selectActiveTrack}
											updateTheme={this.updateTheme}
											selectActiveTheme={this.props.selectActiveTheme}
										/>
									</CardBody>
								</Collapse>
							</Card>
						}
					</Col>
					<Col md='3'>
						<Row>
							<Col className="justify-content-end d-flex">
								<SearchTheme focusActiveTheme={this.focusActiveTheme} />
							</Col>
						</Row>
						<ListGroupItemHeading className="text-right pt-3">
							Workspace Themes
						</ListGroupItemHeading>
						<ThemeResults
							activeThemes={this.props.activeThemes}
							removeTheme={this.props.removeTheme}
							focusActiveTheme={this.focusActiveTheme}
							removeAllThemes={this.props.removeAllThemes}
							activeTrack={this.props.activeTrack}
						/>
					</Col>
				</Row>
				<Modal isOpen={this.state.themeNameModal.open} toggle={() => this.toggle('themeNameModal')} >
					<Form id="themeName" onSubmit={val => { this.updateTheme({ ...this.props.activeTheme, theme: val.theme }); this.toggle('themeNameModal') }} >
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('themeNameModal')}>Theme Name</ModalHeader>
								<ModalBody>
									<WrappedText
										field="theme"
										validate={required}
										initialValue={this.props.activeTheme.theme}
									/>
									<div className='invalid-feedback'>{formState.errors.theme}</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="button" color="secondary" onClick={this.deleteTheme}>Delete</Button>
									<Button type="button" onClick={() => this.toggle('themeNameModal')} color="dark">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
				<Modal isOpen={this.state.trackNameModal.open} toggle={() => this.toggle('trackNameModal')} >
					<Form id="trackName" onSubmit={val => { this.props.updateTrack({ ...this.props.activeTrack, name: val.name }); this.props.selectActiveTrack({ ...this.props.activeTrack, name: val.name }); axios.post('/track/update', { _id: this.props.activeTrack._id, name: val.name }); this.toggle('trackNameModal'); }} >
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('trackNameModal')}>Track Name</ModalHeader>
								<ModalBody>
									<WrappedText
										field="name"
										validate={required}
										initialValue={this.props.activeTrack.name}
									/>
									<div className='invalid-feedback'>{formState.errors.name}</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="button" color="secondary" onClick={this.deleteTrack}>Delete</Button>
									<Button type="button" onClick={() => this.toggle('trackNameModal')} color="dark">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
				<Modal isOpen={this.state.confirmModal.open} toggle={() => this.toggle('confirmModal')} >
					<ModalHeader toggle={() => this.toggle('confirmModal')}>{this.state.confirmModal.header}</ModalHeader>
					{this.state.confirmModal.body &&
						<ModalBody>
							{this.state.confirmModal.body}
						</ModalBody>
					}
					<ModalFooter>
						<Button type="button" color="primary" onClick={() => this.state.confirmModal.onSubmit(true)}>Yes</Button>
						<Button type="button" color="secondary" onClick={() => this.state.confirmModal.onSubmit(false)}>No</Button>
					</ModalFooter>
				</Modal>
			</Container>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Builder);
