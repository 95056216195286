import React from 'react';
import {
	Container,
	Row,
	Col,
	Button,
	InputGroup,
	InputGroupAddon,
	FormGroup,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading
} from 'reactstrap';
import {
	setGame,
	startNewGame,
	unsubscribeToGame,
	subscribeToGame,
	emitGameStatus
} from '../../redux/actionCreators';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { WrappedText } from '../utilities/formElements';
import { Form } from 'informed';
import { required } from '../utilities/Validation';
import { uppercase, alphaOnly } from '../utilities/Mask';
import axios from 'axios';
import { error } from '../utilities/tools';
import Controls from './Controls';

const mapStateToProps = state => ({
	gameState: state.gameState,
	gameStatus: state.gameStatus
});

const mapDispatchToProps = dispatch => ({
	setGame: game => dispatch(setGame(game)),
	startNewGame: code => dispatch(startNewGame(code)),
	unsubscribeToGame: code => dispatch(unsubscribeToGame(code)),
	subscribeToGame: (code, cb) => dispatch(subscribeToGame(code, cb)),
	emitGameStatus: (code, status, type) => dispatch(emitGameStatus(code, status, type))
});


class DJ extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			screen: 'initial',
			error: null
		};

		this.isCancelled = false;
		this.api = null;
		this.teamApi = null;

		this.getGame = this.getGame.bind(this);
		this.addTeam = this.addTeam.bind(this);
	}

	componentDidMount() {
		const roomCode = Cookie.get('roomCode');
		if (roomCode) {
			this.api.setValues({ 'code': roomCode });
			this.api.setTouched('code', true);
		}


	}

	componentWillUnmount() {
		this.isCancelled = true;
		this.props.unsubscribeToGame(this.props.gameState.code);
	}

	getGame(val) {
		let code;
		let newGame;
		if (val) {
			code = val.code ? val.code : Cookie.get('roomCode');
			newGame = false;
		} else {
			newGame = true;
			code = '';
		}

		axios.get('/game?code=' + code)
			.then(res => {
				if (res.data) {
					if (newGame) {
						this.props.startNewGame(res.data.code);
					} else {
						this.props.subscribeToGame(res.data.code);
					}

					Cookie.set('roomCode', res.data.code);
					this.props.setGame(res.data);
					!this.isCancelled && this.setState({ screen: newGame ? 'confirm' : 'game', error: null });
				} else {
					!this.isCancelled && this.setState({ error: 'Sorry, that code is not valid.  Please try again.' })
				}
				
				

			})
			.catch(err => error(err));
	}

	addTeam(val) {
		this.props.emitGameStatus(this.props.gameState.code, { name: val.name }, 'addTeam');
		this.teamApi.reset();
	}

	render() {
		return (
			<React.Fragment>
				{
					this.state.screen === 'initial' &&
					<Container fluid className="p-3 h-100 bg-primary text-light">
						<Row className="h-100">
							<Col className="h-100 d-flex align-items-center justify-content-center flex-column">
								<Button size="lg" color="secondary" onClick={() => this.getGame()}>New Game</Button>
								<h4 className="pt-3">-or-</h4>
								<Form id="code" onSubmit={this.getGame} getApi={api => this.api = api}>
									<FormGroup className="pt-2">
										<InputGroup>
											<WrappedText
												id="code"
												placeholder="room code"
												field="code"
												validate={required}
												mask={val => alphaOnly(uppercase(val))}
											/>
											<InputGroupAddon addonType="append">
												<Button type="submit" color="secondary">></Button>
											</InputGroupAddon>
										</InputGroup>
										<div className="text-danger small">{this.state.error}</div>
									</FormGroup>
								</Form>
							</Col>
						</Row>
					</Container>
				}
				{this.state.screen === 'confirm' &&
					<Container fluid className="p-3 h-100 bg-primary text-light">
						<Row>
							<Col xs="4">
								<ListGroup className="text-dark">
									<ListGroupItem>
										<ListGroupItemHeading>Teams</ListGroupItemHeading>
									</ListGroupItem>
									{this.props.gameStatus.teams && this.props.gameStatus.teams.map((el, index) =>
										<ListGroupItem key={index}>
											{el.name}
										</ListGroupItem>
									)}
									<Form id="addTeam" onSubmit={this.addTeam} getApi={api => this.teamApi = api} >
										<FormGroup>
											<InputGroup>
												<WrappedText
													id="name"
													field="name"
													validate={required}
													placeholder="add a team"
												/>
												<InputGroupAddon addonType="append">
													<Button type="submit" color="secondary">+</Button>
												</InputGroupAddon>
											</InputGroup>
										</FormGroup>
									</Form>
								</ListGroup>
							</Col>
							<Col xs="8">
								<h2 className="cabin display-1 text-secondary">Room Code</h2>
								<h1 className="neucha display-1">{this.props.gameState.code}</h1>
								<Button size="lg" color="secondary" onClick={() => {!this.isCancelled && this.setState({ screen: 'game' }); this.props.emitGameStatus(this.props.gameState.code, { started: true});}} type="button">Start Game</Button>
							</Col>
						</Row>
					</Container>
				}
				{this.state.screen === 'game' &&
					<Controls />

				}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DJ);