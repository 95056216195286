import React from 'react';
import {
	Row,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	Button,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { error } from '../utilities/tools';
import {
	addRound,
	setGame,
	emitGameStatus,
	unsubscribeToGame
} from '../../redux/actionCreators';
import Player from '../player/Player';
import ReactTimeout from 'react-timeout';
import { WrappedText } from '../utilities/formElements';
import { Form } from 'informed';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
import Cookie from 'js-cookie';

const mapStateToProps = state => ({
	gameState: state.gameState,
	gameStatus: state.gameStatus
});

const mapDispatchToProps = dispatch => ({
	addRound: round => dispatch(addRound(round)),
	setGame: game => dispatch(setGame(game)),
	emitGameStatus: (code, update, type) => dispatch(emitGameStatus(code, update, type)),
	unsubscribeToGame: code => dispatch(unsubscribeToGame(code))
});

class Controls extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentRound: 0,
			currentTrack: 0,
			disabledTrackHints: [],
			disabledArtistHints: [],
			disabledThemeHints: []
		};

		this.isCancelled = false;

		this.newRound = this.newRound.bind(this);
		this.selectTrack = this.selectTrack.bind(this);
		this.nextTrack = this.nextTrack.bind(this);
		this.previousTrack = this.previousTrack.bind(this);
		this.updateTrack = this.updateTrack.bind(this);
		this.newThemeHint = this.newThemeHint.bind(this);
		this.revealThemeHint = this.revealThemeHint.bind(this);
		this.newArtistHint = this.newArtistHint.bind(this);
		this.revealTrackHint = this.revealTrackHint.bind(this);
		this.newTrackHint = this.newTrackHint.bind(this);
		this.revealTrackHint = this.revealTrackHint.bind(this);
		this.endGame = this.endGame.bind(this);
		this.changeScore = this.changeScore.bind(this);
		this.partyMode = this.partyMode.bind(this);
		this.selectRound = this.selectRound.bind(this);
		this.markGuessed = this.markGuessed.bind(this);
		this.letItPlay = this.letItPlay.bind(this);
		this.seek = this.seek.bind(this);

		this.themeHintApi = null;
		this.artistHintApi = null;
		this.trackHintApi = null;
	}

	componentDidMount() {
		// fetch a round if this is a new game
		if (this.props.gameState.rounds.length === 0) {
			this.newRound(2);
			this.props.setTimeout(() => {
				this.selectRound(0);
				this.props.emitGameStatus(this.props.gameState.code, { loading: false });
			}, 3000);

		} else if (this.props.gameState.rounds.length === 1) {
			// add a round if somehow there is only one
			this.newRound(1);
		}

		// if game was never started, start it now
		if (!this.props.gameStatus.started) {
			this.props.emitGameStatus(this.props.gameState.code, { started: true });
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.gameStatus.players && this.props.gameStatus.players) {
			const oldEmotes = prevProps.gameStatus.players.map(el => el.emote);
			const newEmotes = this.props.gameStatus.players.map(el => el.emote);

			for (let i = 0; i < newEmotes.length; i++) {
				if (oldEmotes[i] !== newEmotes[i] && newEmotes[i] === 'LetPlay') {
					this.letItPlay();
				}
			}
		}

		if (this.props.gameStatus.round && (this.props.gameStatus.round.trackIndex !== this.state.currentTrack || this.props.gameStatus.round.number - 1 !== this.state.currentRound)) {
			!this.isCancelled && this.setState({
				currentTrack: this.props.gameStatus.round.trackIndex,
				currentRound: this.props.gameStatus.round.number - 1
			});
		}
	}

	newRound(num = 1) {
		for (let i = 0; i < num; i++) {
			axios.get('/game/round?code=' + this.props.gameState.code)
				.then(res => {
					this.props.addRound(res.data);
				})
				.catch(err => error(err));
		}
	}

	selectRound(rnum, pause, tnum = 0) {
		this.setState({ currentRound: rnum, currentTrack: tnum }, () => {
			this.selectTrack(tnum, pause);

			const currentRound = this.props.gameState.rounds[this.state.currentRound];
			const tracks = currentRound.tracks.map(track => ({
				trackGuessed: false,
				artistGuessed: false,
				name: track.name,
				artist: track.artist.map(el => el.name).join(', '),
				albumCover: track.albumCover
			}));
			// emit relevant parts of state to socket
			this.props.emitGameStatus(this.props.gameState.code, {
				round: {
					number: this.state.currentRound + 1,
					trackIndex: this.state.currentTrack,
					type: currentRound.roundType,
					theme: (currentRound.theme && currentRound.theme.theme) || '',
					tracks: tracks,
					hints: []
				},
				partyMode: false
			});
		});

	}

	selectTrack(tnum, pause) {
		const track = this.props.gameState.rounds[this.state.currentRound].tracks[tnum];
		const defaultStamp = track.timeStamps.find(el => el.default);
		const startTime = defaultStamp ? defaultStamp.time : 0;

		const query = pause ? '?customStart=' + startTime + '&pause=true' : '?customStart=' + startTime;

		// set state locally
		!this.isCancelled && this.setState({ currentTrack: tnum });

		// play track (or queue and pause track if 'pause' is true)
		axios.get('/spotify/select-track/' + track.id + query)
			.then(() => null)
			.catch(err => error(err));

		// emit relevant parts of state to socket
		this.props.gameStatus.round && this.props.emitGameStatus(this.props.gameState.code, {
			trackIndex: tnum,
			hints: this.props.gameStatus.round.hints.filter(el => el.type === 'theme'),
			partyMode: false
		}, 'selectTrack');
	}

	nextTrack() {
		if (this.props.gameStatus.partyMode) {
			axios.get('/spotify/next')
				.then(() => null)
				.catch(err => error(err));

		} else {
			const currentRound = this.props.gameState.rounds[this.state.currentRound];
			const totalTracks = currentRound.tracks.length;

			if (this.state.currentTrack >= totalTracks - 1) {
				// go to next round and add a new round to buffer
				const round = this.state.currentRound + 1;
				this.selectRound(round);

				// only add a new round if going to the last round
				if (this.props.gameState.rounds.length - 1 === round) {
					this.newRound();
				}
			} else {
				// go to next track in round
				const track = this.state.currentTrack + 1;
				this.selectTrack(track);
			}
		}
	}

	previousTrack() {
		if (this.props.gameStatus.partyMode) {
			axios.get('/spotify/previous')
				.then(() => null)
				.catch(err => error(err));

		} else {
			// manage moving back to previous round
			if (this.state.currentTrack === 0) {
				if (this.state.currentRound === 0) {
					// set to start of first song and pause if you can't go back any further
					this.selectTrack(0, true);
				} else {
					const round = this.state.currentRound - 1;
					const newRound = this.props.gameState.rounds[round];
					const track = newRound.tracks.length - 1;

					this.selectRound(round, false, track);
				}
			} else {
				// go to previous track in round
				const track = this.state.currentTrack - 1;
				this.selectTrack(track);
			}
		}
	}

	updateTrack(update) {
		axios.post('/track/update', update)
			.then(() => {
				axios.get('/game?code=' + this.props.gameState.code)
					.then(res => this.props.setGame(res.data))
					.catch(err => error(err));
			})
			.catch(err => error(err));
	}

	newThemeHint(val) {
		const themeId = this.props.gameState.rounds[this.state.currentRound].theme._id;
		const hints = [...this.props.gameState.rounds[this.state.currentRound].theme.themeHints, val.themeHint];

		axios.post('/theme/update', { _id: themeId, themeHints: hints })
			.then(() => {
				axios.get('/game?code=' + this.props.gameState.code)
					.then(res => {
						this.props.setGame(res.data);
						this.revealThemeHint(val.themeHint);
						this.themeHintApi.reset();
					})
					.catch(err => error(err));
			})
			.catch(err => error(err));
	}

	revealThemeHint(hint) {
		this.props.emitGameStatus(this.props.gameStatus.code, {
			round: {
				...this.props.gameStatus.round,
				hints: [
					...this.props.gameStatus.round.hints,
					{
						hint: hint,
						type: 'theme'
					}
				]
			}
		});
	}

	newArtistHint(val) {
		const trackId = this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack]._id;
		const hints = [...this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].artistHints, val.artistHint];

		axios.post('/track/update', { _id: trackId, artistHints: hints })
			.then(() => {
				axios.get('/game?code=' + this.props.gameState.code)
					.then(res => {
						this.props.setGame(res.data);
						this.revealArtistHint(val.artistHint);
						this.artistHintApi.reset();
					})
					.catch(err => error(err));
			})
			.catch(err => error(err));
	}

	revealArtistHint(hint) {
		this.props.emitGameStatus(this.props.gameStatus.code, {
			round: {
				...this.props.gameStatus.round,
				hints: [
					...this.props.gameStatus.round.hints,
					{
						hint: hint,
						type: 'artist'
					}
				]
			}
		});
	}

	newTrackHint(val) {
		const trackId = this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack]._id;
		const hints = [...this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].songHints, val.trackHint];

		axios.post('/track/update', { _id: trackId, songHints: hints })
			.then(() => {
				axios.get('/game?code=' + this.props.gameState.code)
					.then(res => {
						this.props.setGame(res.data);
						this.revealTrackHint(val.trackHint);
						this.trackHintApi.reset();
					})
					.catch(err => error(err));
			})
			.catch(err => error(err));
	}

	revealTrackHint(hint) {
		this.props.emitGameStatus(this.props.gameStatus.code, {
			round: {
				...this.props.gameStatus.round,
				hints: [
					...this.props.gameStatus.round.hints,
					{
						hint: hint,
						type: 'track'
					}
				]
			}
		});
	}

	endGame() {
		axios.get('/spotify/party-mode')
			.then(() => null)
			.catch(err => error(err));

		this.props.emitGameStatus(this.props.gameStatus.code, { ended: true });
		axios.get('/exp/partier/end-game/' + this.props.gameState.code)
			.then(() => {
				this.props.unsubscribeToGame(this.props.gameStatus.code);
				this.props.history.push('/');
			})
			.catch(err => error(err));

		Cookie.remove('roomCode', { path: '' });

	}

	changeScore(teamId, amount) {
		const teams = this.props.gameStatus.teams.map(el => el);
		teams.forEach(team => {
			if (team._id === teamId) {
				team.score = team.score + amount;
			}
		});

		this.props.emitGameStatus(this.props.gameStatus.code, { teams: teams });
	}

	partyMode() {
		axios.get('/spotify/party-mode')
			.then(() => null)
			.catch(err => error(err));

		// emit relevant parts of state to socket
		this.props.emitGameStatus(this.props.gameState.code, {
			partyMode: true
		});
	}

	markGuessed(feature) {
		switch (feature) {
			case 'artist':
				this.props.emitGameStatus(this.props.gameState.code, {
					round: {
						...this.props.gameStatus.round,
						tracks: this.props.gameStatus.round.tracks.map((el, index) => {
							if (index === this.state.currentTrack) {
								return { ...el, artistGuessed: true };
							} else {
								return el;
							}
						})
					}
				});
				break;
			case 'track':
				this.props.emitGameStatus(this.props.gameState.code, {
					round: {
						...this.props.gameStatus.round,
						tracks: this.props.gameStatus.round.tracks.map((el, index) => {
							if (index === this.state.currentTrack) {
								return { ...el, trackGuessed: true };
							} else {
								return el;
							}
						})
					}
				});
				break;
			case 'theme':
				this.props.emitGameStatus(this.props.gameState.code, {
					round: {
						...this.props.gameStatus.round,
						themeGuessed: true
					}
				});
				break;
			default:
				break;
		}
	}

	letItPlay() {
		return toast.error('Let this one play!', { autoClose: 3000 });
	}

	seek(time) {
		axios.get('/spotify/seek/' + time)
			.then(() => null)
			.catch(err => error(err));
	}

	render() {
		const disabledThemeHints = this.props.gameStatus.round ? this.props.gameStatus.round.hints.filter(el => el.type === 'theme').map(el => el.hint) : [];
		const disabledArtistHints = this.props.gameStatus.round ? this.props.gameStatus.round.hints.filter(el => el.type === 'artist').map(el => el.hint) : [];
		const disabledTrackHints = this.props.gameStatus.round ? this.props.gameStatus.round.hints.filter(el => el.type === 'track').map(el => el.hint) : [];

		return (
			<Container fluid className="p-3 bg-primary text-light h-100 d-flex flex-column">
				<ToastContainer position="top-right" autoClose={5000} style={{ zIndex: 1999 }} />
				<Row>
					<Col>
						<h2>Round {this.state.currentRound + 1}</h2>
					</Col>
					<Col xs="auto">
						<h4>Room Code: {this.props.gameState.code}</h4>
					</Col>
				</Row>
				<Container fluid className="d-md-none">
					<Row>
						<Col>
							<h1>This interface requires a tablet sized screen or larger.</h1>
						</Col>
					</Row>
				</Container>
				<Container fluid className="p-0 d-md-flex h-100 d-none" style={{ position: 'relative' }}>
					<Row className="flex-fill">
						<Col xs="3" className="scrolling-div h-100" style={{ position: 'absolute', overflowY: 'auto' }}>
							<ListGroup className="text-dark">
								<ListGroupItem>
									<h3>Rounds</h3>
								</ListGroupItem>
								{this.props.gameState.rounds.map((el, index) => ({ ...el, index: index })).reverse().map(round =>
									<ListGroupItem
										action
										tag="button"
										key={round.index}
										color={this.state.currentRound === round.index ? 'primary' : 'light'}
										onClick={() => {
											this.selectRound(round.index);
											if (this.props.gameState.rounds.length - 1 === round.index) {
												this.newRound();
											}
										}}
									>
										{`${round.roundType}${round.theme ? ': ' + round.theme.theme : ''}`}
									</ListGroupItem>
								)}
							</ListGroup>
							<div>
								<Button size="lg" className="m-3" color="secondary" onClick={this.endGame}>End Game</Button>
							</div>
						</Col>
						<Col xs={{ size: 6, offset: 3 }}>
							{this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].roundType === 'Theme' ?
								<Row>
									<Col className="text-center">
										<h3>Theme: {this.props.gameState.rounds[this.state.currentRound].theme.theme}</h3>
									</Col>
								</Row>
								:
								<Row>
									<Col className="text-center">
										<h3>Speed Round</h3>
									</Col>
								</Row>
							}
							<Row>
								<Col xs={this.props.gameStatus.teams && this.props.gameStatus.teams.length > 0 ? 6 : 12}>
									{this.props.gameStatus.loading ?
										<h4>Beginning in a moment . . . </h4>
										:
										<Player nextTrack={this.nextTrack} previousTrack={this.previousTrack} />
									}
								</Col>
								<Col xs="6">
									{this.props.gameStatus.teams && this.props.gameStatus.teams.length > 0 &&
										<React.Fragment>
											<h4>Team Scores</h4>
											{this.props.gameStatus.teams.map(team =>
												<Row key={team.name}>
													<Col className="d-flex flex-column justify-content-center">
														<h5>{team.name}</h5>
													</Col>
													<Col xs="auto">
														<h5><Button color="link" className="text-light" style={{ textDecoration: "none" }} onClick={() => this.changeScore(team._id, -1)}>-</Button> <Badge pill>{team.score}</Badge> <Button color="link" style={{ textDecoration: "none" }} className="text-light" onClick={() => this.changeScore(team._id, 1)}>+</Button></h5>
													</Col>
												</Row>
											)}
										</React.Fragment>
									}
									<Row>
										<Col className="d-flex flex-column align-items-end px-1">
											{this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].timeStamps.map(stamp =>
												<div className="py-2" key={stamp._id}>
													<Button type="button" color="light" onClick={() => this.seek(stamp.time)} >{stamp.name}</Button>
												</div>
											)}
										</Col>
									</Row>

								</Col>
							</Row>
							{this.props.gameStatus.partyMode ?
								<Row>
									<Col className="pt-5 text-center">
										<h1 className="cabin">Party Mode!</h1>
									</Col>
								</Row>

								:
								<React.Fragment>
									<Row>
										{this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].theme &&
											<Col xs="4" className="px-1">
												<ListGroup className="py-2 text-dark">
													<ListGroupItem>
														<ListGroupItemHeading>Theme Hints</ListGroupItemHeading>
													</ListGroupItem>
													{this.props.gameState.rounds[this.state.currentRound].theme.themeHints.map((hint, index) =>
														<ListGroupItem
															key={index}
															type="button"
															onClick={() => this.revealThemeHint(hint)}
															action
															disabled={disabledThemeHints.includes(hint)}
															style={{ background: disabledThemeHints.includes(hint) ? '#ccc' : '#fff' }}
														>
															{hint}
														</ListGroupItem>
													)}
													<Form id="newTag" getApi={api => this.themeHintApi = api} onSubmit={this.newThemeHint}>
														<InputGroup>
															<WrappedText
																field="themeHint"
																placeholder="add new hint"
															/>
															<InputGroupAddon addonType="append">
																<Button color="secondary">+</Button>
															</InputGroupAddon>
														</InputGroup>
													</Form>
												</ListGroup>
											</Col>
										}
										<Col xs={this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].theme ? "4" : "6"} className="px-1">
											<ListGroup className="py-2 text-dark">
												<ListGroupItem>
													<ListGroupItemHeading>Artist Hints</ListGroupItemHeading>
												</ListGroupItem>
												{this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].artistHints.map((hint, index) =>
													<ListGroupItem
														key={index}
														type="button"
														onClick={() => this.revealArtistHint(hint)}
														action
														disabled={disabledArtistHints.includes(hint)}
														style={{ background: disabledArtistHints.includes(hint) ? '#ccc' : '#fff' }}
													>
														{hint}
													</ListGroupItem>
												)}
												<Form id="newTag" getApi={api => this.artistHintApi = api} onSubmit={this.newArtistHint}>
													<InputGroup>
														<WrappedText
															field="artistHint"
															placeholder="add new hint"
														/>
														<InputGroupAddon addonType="append">
															<Button color="secondary">+</Button>
														</InputGroupAddon>
													</InputGroup>
												</Form>
											</ListGroup>
										</Col>
										<Col xs={this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].theme ? "4" : "6"} className="px-1">
											<ListGroup className="py-2 text-dark">
												<ListGroupItem>
													<ListGroupItemHeading>Track Hints</ListGroupItemHeading>
												</ListGroupItem>
												<ListGroupItem
													type="button"
													onClick={() => this.revealTrackHint('ALBUM')}
													action
													disabled={disabledTrackHints.includes('ALBUM')}
													style={{ background: disabledTrackHints.includes('ALBUM') ? '#ccc' : '#fff' }}
												>
													<img height="30" src={this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].albumCover} alt="albumCover" /> Album Cover
												</ListGroupItem>
												{this.props.gameState.rounds[this.state.currentRound] && this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].songHints.map((hint, index) =>
													<ListGroupItem
														key={index}
														type="button"
														onClick={() => this.revealTrackHint(hint)}
														action
														disabled={disabledTrackHints.includes(hint)}
														style={{ background: disabledTrackHints.includes(hint) ? '#ccc' : '#fff' }}
													>
														{hint}
													</ListGroupItem>
												)}
												<Form id="newTag" getApi={api => this.trackHintApi = api} onSubmit={this.newTrackHint}>
													<InputGroup>
														<WrappedText
															field="trackHint"
															placeholder="add new hint"
														/>
														<InputGroupAddon addonType="append">
															<Button color="secondary">+</Button>
														</InputGroupAddon>
													</InputGroup>
												</Form>
											</ListGroup>
										</Col>
									</Row>
									{this.props.gameState.rounds.length > 0 &&
										<Row className="pt-4">
											<Col xs="4">
												<FormGroup check>
													<Input
														type="checkbox"
														id="speedEligible"
														checked={this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].speedEligible}
														onChange={(e) => this.updateTrack({ _id: this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack]._id, speedEligible: e.target.checked })}
													/>
													<label htmlFor="speedEligible">Speed Eligible</label>
												</FormGroup>
												<FormGroup check>
													<Input
														type="checkbox"
														id="goodSong"
														checked={this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].goodSong}
														onChange={(e) => this.updateTrack({ _id: this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack]._id, goodSong: e.target.checked })}
													/>
													<label htmlFor="goodSong">Party Song</label>
												</FormGroup>
												<FormGroup check>
													<Input
														type="checkbox"
														id="wellKnown"
														checked={this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack].wellKnown}
														onChange={(e) => this.updateTrack({ _id: this.props.gameState.rounds[this.state.currentRound].tracks[this.state.currentTrack]._id, wellKnown: e.target.checked })}
													/>
													<label htmlFor="wellKnown">Well Known</label>
												</FormGroup>
											</Col>
											<Col xs="4" className="text-center">
												{!this.props.gameStatus.partyMode && <Button size="lg" color="secondary" onClick={this.partyMode}>Party Mode</Button>}
											</Col>
											<Col xs="4">
												{this.props.gameState.rounds[this.state.currentRound].theme &&
													<div className="text-right py-2">
														<Button color="light" disabled={this.props.gameStatus.round && this.props.gameStatus.round.themeGuessed} onClick={() => this.markGuessed('theme')}>Theme Guessed</Button>
													</div>
												}
												<div className="text-right py-2">
													<Button color="light" disabled={this.props.gameStatus.round && this.props.gameStatus.round.tracks && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex] && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artistGuessed} onClick={() => this.markGuessed('artist')}>Artist Guessed</Button>
												</div>
												<div className="text-right py-2">
													<Button color="light" disabled={this.props.gameStatus.round && this.props.gameStatus.round.tracks && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex] && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].trackGuessed} onClick={() => this.markGuessed('track')}>Track Guessed</Button>
												</div>

											</Col>
										</Row>
									}
								</React.Fragment>
							}
						</Col>
						<Col xs="3" >
							<ListGroup className="text-dark">
								<ListGroupItem>
									<h3>Tracks</h3>
								</ListGroupItem>
								{this.props.gameState.rounds.length > 0 && this.props.gameState.rounds[this.state.currentRound].tracks.map((track, index) =>
									<ListGroupItem
										action
										tag="button"
										key={index}
										color={this.state.currentTrack === index ? 'primary' : 'light'}
										onClick={() => this.selectTrack(index)}
									>
										<Row>
											<Col xs="auto">
												<img height="30" src={track.albumCover} alt="albumCover" />
											</Col>
											<Col>
												<div>
													{track.name}
												</div>
												<div><em>
													{track.artist.map(el => el.name).join(', ')}
												</em></div>
											</Col>
										</Row>
									</ListGroupItem>
								)}
							</ListGroup>
						</Col>
					</Row>
				</Container>


			</Container>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReactTimeout(Controls)));
