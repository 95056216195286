import React from 'react';
import {
	Container,
	Row,
	Col,
	Button,
	Badge,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import { WrappedText } from '../utilities/formElements';
import { Form } from 'informed';
import { connect } from 'react-redux';
import { emitGameStatus } from '../../redux/actionCreators';


const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

const mapDispatchToProps = dispatch => ({
	emitGameStatus: (code, update) => dispatch(emitGameStatus(code, update))
});

class Scorekeeper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			code: null,
			manualModal: {
				open: false,
				team: {}
			},
			teamModal: {
				open: false,
				type: null
			}
		};

		this.changeScore = this.changeScore.bind(this);
		this.toggle = this.toggle.bind(this);
		this.markScored = this.markScored.bind(this);
		this.swapScore = this.swapScore.bind(this);

		this.isCancelled = false;
		this.codeApi = null;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	changeScore(teamId, amount, reset = false) {
		const teams = this.props.gameStatus.teams.map(el => {
			return Object.assign({}, el);
		});
		teams.forEach(team => {
			if (team._id === teamId) {
				if (amount === 'theme') {
					let tracksGuessed = this.props.gameStatus.round.trackIndex;
					if (this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].trackGuessed) {
						tracksGuessed++;
					}

					switch (tracksGuessed) {
						case 0:
						case 1:
							break;
						case 2:
							team.score = team.score + 1;
							break;
						case 3:
							team.score = team.score + 3;
							break;
						case 4:
							team.score = team.score + 6;
							break;
						case 5:
							team.score = team.score + 10;
							break;
						default:
							break;
					}
				} else if (reset) {
					team.score = amount;
				} else {
					team.score = team.score + amount;
				}
			}
		});

		this.props.emitGameStatus(this.props.gameStatus.code, { teams: teams });
	}

	toggle(modal) {
		!this.isCancelled && this.setState(prevState => ({ ...prevState, [modal]: { ...prevState[modal], open: !prevState[modal].open } }));
	}

	markScored(feature) {
		switch (feature) {
			case 'artist':
				this.props.emitGameStatus(this.props.gameStatus.code, {
					round: {
						...this.props.gameStatus.round,
						tracks: this.props.gameStatus.round.tracks.map((el, index) => {
							if (index === this.props.gameStatus.round.trackIndex) {
								return { ...el, artistScored: true, artistGuessed: true };
							} else {
								return el;
							}
						})
					}
				});
				break;
			case 'track':
				this.props.emitGameStatus(this.props.gameStatus.code, {
					round: {
						...this.props.gameStatus.round,
						tracks: this.props.gameStatus.round.tracks.map((el, index) => {
							if (index === this.props.gameStatus.round.trackIndex) {
								return { ...el, trackScored: true, trackGuessed: true };
							} else {
								return el;
							}
						})
					}
				});
				break;
			case 'theme':
				this.props.emitGameStatus(this.props.gameStatus.code, {
					round: {
						...this.props.gameStatus.round,
						themeScored: true,
						themeGuessed: true
					}
				});
				break;
			default:
				break;
		}
	}

	swapScore() {
		const teams = this.props.gameStatus.teams.map(el => Object.assign({}, el));
		const lowestScore = teams[0].score;
		const highestScore = teams[teams.length - 1].score;

		teams[0].score = highestScore;
		teams[teams.length - 1].score = lowestScore;

		this.props.emitGameStatus(this.props.gameStatus.code, { teams: teams });
	}

	render() {
		return (
			<Container fluid className="h-100 bg-primary text-light">
				{this.props.gameStatus.started ?
					<Row className="h-100">
						<Col className="d-flex flex-column h-100">
							<Row>
								<Col>
									<h1 className="neucha">Scorekeeper</h1>
									<p>Please Note: clicking buttons here WILL change the score.</p>
								</Col>
								{this.state.code &&
									<Col xs="auto" className="pt-2">Room Code: {this.props.gameStatus.code}</Col>
								}
							</Row>
							{this.props.gameStatus.teams.map(team =>
								<div className="py-4" key={team._id}>
									<Row>
										<Col>
											<h1 className="cabin">{team.name}</h1>
										</Col>
										<Col xs="auto">
											<h1 style={{ cursor: 'pointer' }} onClick={() => !this.isCancelled && this.setState({ manualModal: { open: true, team: team } })}>
												<Badge>
													{team.score}
												</Badge>
											</h1>
										</Col>
									</Row>
								</div>
							)}
							<Row>
								<Col xs="4">
									<Button
										color="light"
										disabled={
											this.props.gameStatus.round &&
											this.props.gameStatus.round.tracks &&
											this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex] &&
											this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].trackScored
										}
										onClick={() => this.setState({ teamModal: { open: true, type: 'track' } })}
									>
										Track
												</Button>
								</Col>
								<Col xs="4">
									<Button
										color="light"
										disabled={
											this.props.gameStatus.round &&
											this.props.gameStatus.round.tracks &&
											this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex] &&
											this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artistScored
										}
										onClick={() => this.setState({ teamModal: { open: true, type: 'artist' } })}>Artist</Button>
								</Col>
								<Col xs="4">
									<Button
										color="light"
										disabled={
											this.props.gameStatus.round &&
											this.props.gameStatus.round.themeScored
										}
										onClick={() => this.setState({ teamModal: { open: true, type: 'theme' } })}
									>Theme</Button>
								</Col>
							</Row>
							<Button
								className="mt-4"
								color="light"
								onClick={this.swapScore}
							>Swap Scores</Button>
						</Col>
					</Row>
					:
					<Row>
						<Col>
							<h1>The game has not yet started.  Come back later.</h1>
						</Col>
					</Row>
				}
				<Modal id="manualModal" isOpen={this.state.manualModal.open} toggle={() => this.toggle('manualModal')}>
					<ModalHeader toggle={() => this.toggle('manualModal')}>
						Update Score for {this.state.manualModal.team.name}
					</ModalHeader>
					<Form id="manualScore" onSubmit={val => { this.changeScore(this.state.manualModal.team._id, val.score || 0, true); this.toggle('manualModal'); }}>
						<ModalBody>
							<WrappedText
								id="score"
								field="score"
								initialValue={this.state.manualModal.team.score}
								type="number"
							/>
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" type="submit">Update</Button>
							<Button color="primary" type="button" onClick={() => this.toggle('manualModal')}>Cancel</Button>
						</ModalFooter>
					</Form>
				</Modal>
				<Modal id="teamModal" isOpen={this.state.teamModal.open} toggle={() => this.toggle('teamModal')}>
					<ModalHeader toggle={() => this.toggle('teamModal')}>Who Scored?</ModalHeader>
					<ModalBody>
						<Row>
							<Col className="d-flex flex-column align-items-center">
								{this.props.gameStatus.teams.map(team =>
									<Button
										key={team._id}
										className="my-2"
										type="button"
										onClick={() => {
											this.markScored(this.state.teamModal.type);
											this.changeScore(team._id, this.state.teamModal.type === 'theme' ? 'theme' : 1)
											this.toggle('teamModal');
										}}
									>
										{team.name}
									</Button>
								)}
							</Col>
						</Row>

					</ModalBody>
					<ModalFooter>
						<Button color="primary" type="button" onClick={() => this.toggle('teamModal')}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</Container>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Scorekeeper);