import React from 'react';
import posed from 'react-pose';
import ReactTimeout from 'react-timeout';
import SplitText from 'react-pose-text';
import classnames from 'classnames';

const RoundPose = posed.div({
	final: {
		position: 'absolute',
		top: 10,
		textAlign: 'center',
		width: 300,
		height: 50,
		transition: {
			duration: 500,
		}
	},
	enter: {
		width: 1500,
		height: 300,
		position: 'absolute',
		textAlign: 'center',
		transition: {
			duration: 300,
		}
	},
	exit: {
		width: 300,
		height: 50,
		position: 'absolute',
		top: 300,
		bottom: 0,
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		transition: {
			duration: 300,
		}
	}
});

const textPoses = {
	exit: {
		opacity: 0,
		y: 100,
		fontSize: '1em',
	},
	enter: {
		opacity: 1,
		y: 0,
		delay: ({ charIndex }) => charIndex * 75,
		fontSize: '5em',
	},
	final: {
		fontSize: '1em',
		duration: 300
	}
};


class RoundAnimation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			status: 'enter'
		}
	}

	componentDidMount() {
		this.props.setTimeout(() => {
			this.setState({ status: 'final' });
		}, 1000);

		this.props.setTimeout(() => {
			this.setState({ status: 'gone' });
			this.props.reset('round');
		}, 2000);
	}


	render() {
		return (
			<div className={classnames({
				'w-100': true,
				'h-100': true,
				'd-none': this.state.status === 'gone',
				'bg-primary': true
			})}>
				<RoundPose pose={this.state.status} initialPose="exit">
					<h1 className="display-4 cabin">
						<SplitText initialPose="exit" pose={this.state.status} charPoses={textPoses}>
							{`Round ${this.props.number}`}
						</SplitText>
					</h1>
				</RoundPose>
			</div>
		);
	}
}

export default ReactTimeout(RoundAnimation);