import React from 'react';
import { DropTarget } from 'react-dnd';
import { DnDTypes } from '../utilities/constants';
import {
	ListGroup,
	ListGroupItem,
	Button,
	InputGroup,
	InputGroupAddon
} from 'reactstrap';
import { WrappedText } from '../utilities/formElements';
import { Form } from 'informed';
import { required } from '../utilities/Validation';
import { TrackListItem, ThemeListItem } from './draggableComponents';
import axios from 'axios';

const dropTarget = {
};

const collect = (connect, monitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop(),
		itemType: monitor.getItemType(),
	}
}

class TrackResults extends React.Component {
	render() {
		const themeTracks = this.props.activeTheme.eligibleTracks ? this.props.activeTheme.eligibleTracks.map(el => el._id) : [];

		return (this.props.connectDropTarget(
			<div>
				<ListGroup className="scrolling-div" style={{ maxHeight: '80vh', overflow: 'auto' }}>
					{this.props.activeTracks.length === 0 ?
						<ListGroupItem>
							No tracks selected
								</ListGroupItem>
						:
						<React.Fragment>
							{this.props.activeTracks.map(track =>
								<TrackListItem
									track={track}
									focusActiveTrack={this.props.focusActiveTrack}
									removeTrack={this.props.removeTrack}
									key={track._id}
									bgColor={themeTracks.includes(track._id) ? '#e1e1e1' : 'white'}
									/>
							)}
							<ListGroupItem>
								<Button block className="bg-gradient-theme-left border-0" onClick={this.props.removeAllTracks}>Remove All Tracks From Workspace</Button>
							</ListGroupItem>
						</React.Fragment>
					}
				</ListGroup>
			</div>
		));
	}
};

class ThemeResults extends React.Component {
	constructor(props) {
		super(props);

		this.newTheme = this.newTheme.bind(this);
		this.isCancelled = false;
		this.newThemeApi = null;

	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	newTheme(val) {
		axios.post('/theme', { theme: val.name })
			.then(res => {
				this.props.focusActiveTheme(res.data);
				this.newThemeApi.reset();
			})
			.catch(err => {
				this.newThemeApi.setError('name', err.response.data.error);
			});

	}


	render() {
		const trackThemes = this.props.activeTrack.themes ? this.props.activeTrack.themes.map(el => el._id) : [];
		
		return (this.props.connectDropTarget(
			<div>
				<ListGroup className="scrolling-div" style={{ maxHeight: '80vh', overflow: 'auto' }}>
					{this.props.activeThemes.length === 0 ?
						<ListGroupItem>
							No themes selected
								</ListGroupItem>
						:
						<React.Fragment>
							{this.props.activeThemes.map(theme =>
								<ThemeListItem
									theme={theme}
									key={theme._id}
									removeTheme={this.props.removeTheme}
									focusActiveTheme={this.props.focusActiveTheme}
									bgColor={trackThemes.includes(theme._id) ? '#e1e1e1' : 'white'}
								/>
							)}
							<ListGroupItem>
								<Button block className="bg-gradient-theme-right border-0" onClick={this.props.removeAllThemes}>Remove All Themes From Workspace</Button>
							</ListGroupItem>
						</React.Fragment>
					}
				</ListGroup>
				<ListGroup>
					<ListGroupItem>
						<Form id="newTheme" onSubmit={this.newTheme} getApi={api => this.newThemeApi = api}>
							{({ formState }) =>
								<InputGroup>
									<WrappedText
										placeholder="new theme"
										field="name"
										validate={required}
									/>
									<InputGroupAddon addonType="append"><Button type="submit">+</Button></InputGroupAddon>
									<div className='invalid-feedback'>{formState.errors.name}</div>
								</InputGroup>
							}
						</Form>
					</ListGroupItem>
				</ListGroup>
			</div>
		));
	}
};

const wrappedTrackResults = DropTarget(DnDTypes.TRACK_SEARCH_RESULT, dropTarget, collect)(TrackResults);
const wrappedThemeResults = DropTarget(DnDTypes.THEME_SEARCH_RESULT, dropTarget, collect)(ThemeResults);

export { wrappedTrackResults as TrackResults, wrappedThemeResults as ThemeResults };