import React from 'react';
import posed from 'react-pose';
import ReactTimeout from 'react-timeout';

const LeaderPose = posed.div({
	enter: {
		width: 750,
		height: 750,
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		borderRadius: '50%',
		color: '#000',
		opacity: 1,
		transition: {
			duration: 750,
			type: 'spring',
			stiffness: 1000,
			damping: 10,
			delay: 500
		}
	},
	exit: {
		width: 0,
		height: 0,
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		borderRadius: '50%',
		color: '#000',
		opacity: 0,
		transition: {
			duration: 0,
			delay: 0
		}
	}
});

class FinalScore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: 0,
			team: this.props.teams[0],
			pose: 'enter'
		};
	}
	componentDidMount() {
		for (let i = 1; i < this.props.teams.length; i++) {
			this.props.setTimeout(() => {
				this.setState({
					team: this.props.teams[i],
					pose: 'exit',
					page: i
				}, () => {
					this.props.setTimeout(() => {
						this.setState({
							pose: 'enter'
						});
					}, 300);

				});

				if (i === this.props.teams.length -1) {
					this.props.setTimeout(() => {
						this.props.credits();
					}, 2500);
				}
			}, 2000 * i);
		}
	}

	render() {
		return (
			<div className="h-100 w-100 bg-primary">
				<LeaderPose pose={this.state.pose} initialPose="exit" className="bg-gradient-theme-top text-light">
					<div style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						maxWidth: 725,
						transform: "translate(-50%, -50%)"
					}}>
						<h1 className="display-1 cabin">
							{this.state.page === this.props.teams.length - 1 ?
								<React.Fragment>
									{this.state.team.name} WINS with {this.state.team.score} points!
								</React.Fragment>
								:
								<React.Fragment>
									{this.state.team.name} has {this.state.team.score} points
								</React.Fragment>

							}

						</h1>
					</div>
				</LeaderPose>
			</div>
		);
	}
}

export default ReactTimeout(FinalScore);