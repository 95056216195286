import React from 'react';
import {MdSearch} from 'react-icons/md';
import {FiCheckCircle, FiXCircle} from 'react-icons/fi';
import {
    Input,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Table,
    Label,
    FormGroup,
} from 'reactstrap';
import axios from 'axios';
import {css} from '@emotion/core';
import {PacmanLoader} from 'react-spinners';
import colors from '../../styles/color';
import {required} from './Validation';
import classNames from 'classnames';
import {addTracks} from '../../redux/actionCreators';
import {connect} from 'react-redux';
import moment from 'moment';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const mapDispatchToProps = dispatch => ({
    addTracks: (tracks) => dispatch(addTracks(tracks))
});

class SearchInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            modal: false,
            results: [],
            loading: false,
            nestedModal: false,
            selectedTrack: {
                errors: {}
            },
            playing: false
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.importTrack = this.importTrack.bind(this);
        this.submitImport = this.submitImport.bind(this);
        this.addTrack = this.addTrack.bind(this);
        this.addTracksWithoutThemes = this.addTracksWithoutThemes.bind(this);

        this.isCancelled = false;
    }

    componentWillUnmount() {
        this.isCancelled = true;
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.value === '') return;

        axios.get('/spotify/search/' + this.state.value)
            .then(res => {
                !this.isCancelled && this.setState({loading: false, results: res.data});
            })
            .catch(err => window.console && console.error(err));
        !this.isCancelled && this.setState({modal: true, loading: true});

    }

    toggle = (nested) => {
        if (nested === true) {
            !this.isCancelled && this.setState(prevState => ({
                ...prevState,
                nestedModal: !prevState.nestedModal,
            }));
        } else {
            !this.isCancelled && this.setState(prevState => ({
                ...prevState,
                results: [],
                modal: !prevState.modal,
                value: prevState.modal ? '' : prevState.value
            }));
        }
    }

    addTrack(tracks) {
        this.props.addTracks(tracks);
        this.toggle();
    }

    importTrack(track) {
        !this.isCancelled && this.setState(prevState => ({
            ...prevState,
            selectedTrack: {...track, customReleaseDate: moment(track.releaseDate).format('YYYY'), errors: {}}
        }));
        this.toggle(true);
    }

    submitImport() {
        const track = Object.assign({}, this.state.selectedTrack);
        delete track.errors;

        axios.post('/track', [track])
            .then(res => {
                const results = this.state.results.map(el => {
                    if (el.id === track.id) {
                        return {...res.data[0], inDb: true};
                    }
                    return el;
                });

                !this.isCancelled && this.setState({results: results});
                this.toggle(true);
            })
            .catch(err => window.console && console.error(err));
    }

    addTracksWithoutThemes = () => {
        axios.get('/track/no-theme')
            .then((res) => {
                this.props.addTracks(res.data);
            })
            .catch(console.log);
    }

    render() {
        return (
            <React.Fragment>
                <Form className="cr-search-form" onSubmit={this.onSubmit}>
                    <MdSearch
                        size="20"
                        className="cr-search-form__icon-search text-secondary mt-2"
                        onClick={this.onSubmit}
                        style={{cursor: 'pointer'}}
                    />
                    <Input
                        type="search"
                        className="cr-search-form__input"
                        placeholder="Search tracks..."
                        value={this.state.value}
                        onChange={(e) => this.setState({value: e.target.value})}
                    />
                    <div className="p-2">
                        <Button type="button" color="secondary" onClick={this.addTracksWithoutThemes}>
                            Add Tracks Without Themes
                        </Button>
                    </div>
                </Form>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Track Search Results</ModalHeader>
                    <ModalBody>
                        {this.state.loading ?
                            <div className="m-2" style={{height: 40}}>
                                <PacmanLoader
                                    color={colors.secondary}
                                    css={override}
                                    size={20}
                                    sizeUnit={'px'}
                                />
                            </div>
                            :
                            <React.Fragment>
                                <Button block className="bg-gradient-theme-left border-0"
                                        onClick={() => this.addTrack(this.state.results.filter(el => el.inDb))}>Add All
                                    Checked Tracks To Workspace</Button>
                                <Table hover>
                                    <tbody>
                                    {this.state.results.map(item =>
                                        <tr
                                            key={item.id}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                if (item.inDb) {
                                                    this.addTrack([item]);
                                                    this.props.focusActiveTrack(item);
                                                } else {
                                                    this.importTrack(item);
                                                }
                                            }}
                                        >
                                            <td>
                                                {item.albumCover &&
                                                    <img src={item.albumCover} alt="album cover" height="75"
                                                         width="75"/>}
                                            </td>
                                            <td>
                                                <div className="lead">{item.name}</div>
                                                <div><em>{item.artist.map(el => el.name).join(', ')}</em></div>
                                            </td>
                                            <td style={{verticalAlign: 'middle', fontSize: '2em'}}>
                                                {item.inDb ? <FiCheckCircle className="text-success"/> : <FiXCircle/>}
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="button" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                    <Modal isOpen={this.state.nestedModal} toggle={() => this.toggle(true)}>
                        <Form onSubmit={this.submitImport}>
                            <ModalHeader toggle={() => this.toggle(true)}>Configure Track</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label htmlFor="name">Customize Name</Label>
                                    <Input
                                        field="name"
                                        value={this.state.selectedTrack.name}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            !this.isCancelled && this.setState(prevState => ({
                                                ...prevState,
                                                selectedTrack: {
                                                    ...prevState.selectedTrack,
                                                    name: value,
                                                    errors: {
                                                        ...prevState.selectedTrack.errors,
                                                        name: required(value)
                                                    }
                                                }
                                            }));
                                        }}
                                        className={classNames({
                                            'is-invalid': !!this.state.selectedTrack.errors.name
                                        })}
                                    />
                                    <div className="invalid-feedback">{this.state.selectedTrack.errors.name}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="name">Customize Release Date</Label>
                                    <Input
                                        field="customReleaseDate"
                                        value={this.state.selectedTrack.customReleaseDate}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            !this.isCancelled && this.setState(prevState => ({
                                                ...prevState,
                                                selectedTrack: {
                                                    ...prevState.selectedTrack,
                                                    customReleaseDate: value,
                                                    errors: {
                                                        ...prevState.selectedTrack.errors,
                                                        customReleaseDate: required(value)
                                                    }
                                                }
                                            }));
                                        }}
                                        className={classNames({
                                            'is-invalid': !!this.state.selectedTrack.errors.name
                                        })}
                                    />
                                    <div
                                        className="invalid-feedback">{this.state.selectedTrack.errors.customReleaseDate}</div>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={!!this.state.selectedTrack.speedEligible}
                                            onChange={(e) => {
                                                const value = e.target.checked;
                                                !this.isCancelled && this.setState(prevState => ({
                                                    ...prevState,
                                                    selectedTrack: {
                                                        ...prevState.selectedTrack,
                                                        speedEligible: value,
                                                    }
                                                }));
                                            }}
                                        /> Speed Round Eligible
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={!!this.state.selectedTrack.goodSong}
                                            onChange={(e) => {
                                                const value = e.target.checked;
                                                !this.isCancelled && this.setState(prevState => ({
                                                    ...prevState,
                                                    selectedTrack: {
                                                        ...prevState.selectedTrack,
                                                        goodSong: value,
                                                    }
                                                }));
                                            }}
                                        /> Party Song
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={!!this.state.selectedTrack.wellKnown}
                                            onChange={(e) => {
                                                const value = e.target.checked;
                                                !this.isCancelled && this.setState(prevState => ({
                                                    ...prevState,
                                                    selectedTrack: {
                                                        ...prevState.selectedTrack,
                                                        wellKnown: value,
                                                    }
                                                }));
                                            }}
                                        /> Well Known
                                    </Label>
                                </FormGroup>
                                <div className="py-2">
                                    {this.state.playing ?
                                        <Button color="secondary" outline onClick={() => {
                                            this.props.pause();
                                            this.setState({playing: false});
                                        }}>Pause</Button>
                                        :
                                        <Button color="secondary" outline onClick={() => {
                                            this.props.playTrack(this.state.selectedTrack && this.state.selectedTrack.id);
                                            this.setState({playing: true});
                                        }}>Preview</Button>
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" type="button" onClick={this.submitImport}>Import</Button>
                                <Button color="secondary" type="button"
                                        onClick={() => this.toggle(true)}>Cancel</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(SearchInput);
