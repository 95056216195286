import React from 'react';
import { connect } from 'react-redux';
import { emitGameStatus } from '../../redux/actionCreators';
import posed, { PoseGroup } from 'react-pose';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaQuestion, FaExclamation, FaRegSmileBeam } from 'react-icons/fa';
import { GiAudioCassette } from 'react-icons/gi';
import { IoMdMicrophone } from 'react-icons/io';
import { GoQuestion, GoDashboard, GoGear } from 'react-icons/go';
import { Col, Row, Button, ButtonGroup, ListGroup, ListGroupItemHeading, ListGroupItem } from 'reactstrap';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import { error } from '../utilities/tools';
import cx from 'classnames';
import Scorekeeper from './Scorekeeper';

const Avatar = posed.div({
	enter: {
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		y: 0,
		margin: 'auto',
		transition: {
			duration: 500,
			type: 'spring',
			stiffness: 75,
			damping: 5,
		}
	},
	exit: {
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		y: 750,
		margin: 'auto',
	}
});

const Emote = posed.div({
	enter: {
		position: 'relative',
		opacity: 1,
		width: "100%",
		height: "100%",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto',
		delay: ({ index }) => index * 150 + 1000,
		transition: {
			duration: 200,
			type: 'spring',
			stiffness: 2000,
			damping: 30,
		}
	},
	exit: {
		position: 'relative',
		opacity: 0,
		width: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto'
	}
});

const EmotePress = posed.div({
	pressable: true,
	init: { scale: 1 },
	press: { scale: 0.8 }
});

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

const mapDispatchToProps = dispatch => ({
	emitGameStatus: (code, update, type) => dispatch(emitGameStatus(code, update, type))
});


class PartierMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			index: 0
		};

		this.emote = this.emote.bind(this);
		this.notify = this.notify.bind(this);

		this.isCancelled = false;
	}

	componentDidUpdate(prevProps) {
		// clear emote
		const prevEmote = prevProps.gameStatus.players ? prevProps.gameStatus.players.find(el => el._id === this.props.playerId).emote : null;
		const newEmote = this.props.gameStatus.players ? this.props.gameStatus.players.find(el => el._id === this.props.playerId).emote : null;

		if (prevEmote !== newEmote && newEmote) this.emote();

		// theme guessed toast
		if (prevProps.gameStatus.round && prevProps.gameStatus.round.themeGuessed === false && this.props.gameStatus.round.themeGuessed === true) {
			this.notify(this.props.gameStatus.round.theme, 'theme', true);
		}

		// track guessed toast
		if (prevProps.gameStatus.round && prevProps.gameStatus.round.tracks[prevProps.gameStatus.round.trackIndex].trackGuessed === false && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].trackGuessed === true) {
			this.notify(this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].name, 'track', true);
		}

		// artist guessed toast
		if (prevProps.gameStatus.round && prevProps.gameStatus.round.tracks[prevProps.gameStatus.round.trackIndex].artistGuessed === false && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artistGuessed === true) {
			this.notify(this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artist, 'artist', true);
		}

		// new hint added
		const oldHints = prevProps.gameStatus.round ? prevProps.gameStatus.round.hints : [];
		const newHints = prevProps.gameStatus.round ? this.props.gameStatus.round.hints : [];

		if (oldHints.length < newHints.length) {
			this.notify(newHints[newHints.length - 1].hint, newHints[newHints.length - 1].type);
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	emote(type) {
		this.props.emitGameStatus(this.props.gameStatus.code, { player: this.props.playerId, type: type }, 'emote');

		if (type === 'Love') {
			const player = this.props.gameStatus.players.find(el => el._id === this.props.playerId);
			axios.post('/spotify/add-to-playlist', {
				code: this.props.gameStatus.code,
				name: player.name,
				email: player.email
			})
				.then(res => {
					res.data && this.notify('track added to your playlist', null, false, 3000);
				})
				.catch(err => error(err));
		} if (type === 'LetPlay') {
			this.notify('the DJ has been notified of your command', null, false, 3000);
		}
	}

	notify(message, type = null, answer = false, delay = 10000) {
		const msg = ({ closeToast }) => {
			switch (type) {
				case 'theme':
					if (answer) {
						return (
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									<FaExclamation />
								</Col>
								<Col className=" d-flex flex-column align-items-center justify-content-center">
									<div>The Theme Was Guessed</div>
									<div className="lead">{message}</div>
								</Col>
								<Col xs="auto" className="d-flex align-items-center">
									<FaExclamation />
								</Col>
							</Row>
						);
					} else {
						return (
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									<FaQuestion />
								</Col>
								<Col className=" d-flex flex-column align-items-center justify-content-center text-center">
									<div>Theme Hint</div>
									<div className="lead">{message}</div>
								</Col>
								<Col xs="auto" className="d-flex align-items-center">
									<FaQuestion />
								</Col>
							</Row>
						);
					}
				case 'artist':
					if (answer) {
						return (
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									<FaExclamation />
								</Col>
								<Col className=" d-flex flex-column align-items-center justify-content-center">
									<div>The Artist Was Guessed</div>
									<div className="lead">{message}</div>
								</Col>
								<Col xs="auto" className="d-flex align-items-center">
									<FaExclamation />
								</Col>
							</Row>
						);
					} else {
						return (
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									<IoMdMicrophone />
								</Col>
								<Col className=" d-flex flex-column align-items-center justify-content-center">
									<div>Artist Hint</div>
									<div className="lead">{message}</div>
								</Col>
								<Col xs="auto" className="d-flex align-items-center">
									<IoMdMicrophone />
								</Col>
							</Row>
						);
					}
				case 'track':
					if (answer) {
						return (
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									<FaExclamation />
								</Col>
								<Col className=" d-flex flex-column align-items-center justify-content-center">
									<div>The Track Was Guessed</div>
									<div className="lead">{message}</div>
								</Col>
								<Col xs="auto" className="d-flex align-items-center">
									<FaExclamation />
								</Col>
							</Row>
						);
					} else {
						return (
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									<GiAudioCassette />
								</Col>
								<Col className=" d-flex flex-column align-items-center justify-content-center">
									<div>Track Hint</div>
									<div className="lead">{message}</div>
								</Col>
								<Col xs="auto" className="d-flex align-items-center">
									<GiAudioCassette />
								</Col>
							</Row>
						);
					}
				default:
					return message;
			}
		};

		return toast(msg, { autoClose: delay });
	}

	render() {
		const { index } = this.state;

		let player = null;
		if (this.props.gameStatus.players) player = this.props.gameStatus.players.find(el => el._id === this.props.playerId);

		const themeGuessed = this.props.gameStatus.round ? this.props.gameStatus.round.themeGuessed : false;
		const artistGuessed = this.props.gameStatus.round ? this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artistGuessed : false;
		const trackGuessed = this.props.gameStatus.round ? this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].trackGuessed : false;

		let artistHints = [];
		let themeHints = [];
		let trackHints = [];
		if (this.props.gameStatus.round) {
			themeHints = themeGuessed ? [{
				type: 'theme',
				hint: this.props.gameStatus.round.theme,
				_id: 'uniqueKey'
			}] : this.props.gameStatus.round.hints.filter(el => el.type === 'theme');

			artistHints = artistGuessed ? [{
				type: 'artist',
				_id: 'uniqueKey',
				hint: this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artist
			}] : this.props.gameStatus.round.hints.filter(el => el.type === 'artist');

			trackHints = trackGuessed ? [{
				type: 'track',
				_id: 'uniqueKey',
				hint: this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].name
			}] : this.props.gameStatus.round.hints.filter(el => el.type === 'track');
		}


		return (
			<div className="w-100 h-100 d-flex flex-column">
				{player &&
					<SwipeableViews index={index} onChangeIndex={index => !this.isCancelled && this.setState({ index })}>
						<div className="full-page-slide">
							<div className="emote-container">
								<div className="emote-table">
									<div className="emote-cell-top">
										<div className="emote-top-edge">
											<PoseGroup>
												<Emote key="emote" initialPose="exit" index="0">
													<EmotePress>
														<img
															src={require('../../assets/img/emotes/playerLove.png')}
															alt="love it"
															width="100%"
															onClick={() => this.emote('Love')}
														/>
													</EmotePress>
												</Emote>
											</PoseGroup>
										</div>
									</div>
									<div className="emote-cell-top">
										<div className="emote-top-middle">
											<PoseGroup>
												<Emote key="emote" initialPose="exit" index="1">
													<EmotePress>
														<img
															src={require('../../assets/img/emotes/playerCongrats.png')}
															alt="congrats"
															width="100%"
															onClick={() => this.emote('Congrats')}
														/>
													</EmotePress>
												</Emote>
											</PoseGroup>
										</div>
									</div>
									<div className="emote-cell-top">
										<div className="emote-top-edge">
											<PoseGroup>
												<Emote key="emote" initialPose="exit" index="2">
													<EmotePress>
														<img
															src={require('../../assets/img/emotes/playerLetPlay.png')}
															alt="let it play"
															width="100%"
															onClick={() => this.emote('LetPlay')}
														/>
													</EmotePress>
												</Emote>
											</PoseGroup>
										</div>
									</div>
								</div>
								<div style={{ position: 'aboslute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} >
									{player.avatar &&
										<PoseGroup className="w-75 p-3" style={{ maxWidth: 300 }}>
											<Avatar key="avatar" initialPose="exit">
												<img src={require(`../../assets/img/avatars/${player.avatar}.png`)} alt={player.avatar} className="w-100" />
											</Avatar>
										</PoseGroup>
									}
								</div>
								<div className="emote-table">
									<div className="emote-cell-bottom">
										<div className="emote-bottom-edge">
											<PoseGroup>
												<Emote key="emote" initialPose="exit" index="5">
													<EmotePress>
														<img
															src={require('../../assets/img/emotes/playerCurse.png')}
															alt="curses"
															width="100%"
															onClick={() => this.emote('Curse')}
														/>
													</EmotePress>
												</Emote>
											</PoseGroup>
										</div>
									</div>
									<div className="emote-cell-bottom">
										<div className="emote-bottom-middle">
											<PoseGroup>
												<Emote key="emote" initialPose="exit" index="4">
													<EmotePress>
														<img
															src={require('../../assets/img/emotes/playerDance.png')}
															alt="dance"
															width="100%"
															onClick={() => this.emote('Dance')}
														/>
													</EmotePress>
												</Emote>
											</PoseGroup>
										</div>
									</div>
									<div className="emote-cell-bottom">
										<div className="emote-bottom-edge">
											<PoseGroup>
												<Emote key="emote" initialPose="exit" index="3">
													<EmotePress>
														<img
															src={require('../../assets/img/emotes/playerWine.png')}
															alt="wine"
															width="100%"
															onClick={() => this.emote('Wine')}
														/>
													</EmotePress>
												</Emote>
											</PoseGroup>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="full-page-slide p-2">
							<ListGroup>
								<ListGroupItem className="mt-3" color="primary">
									<ListGroupItemHeading>Theme{!themeGuessed && ' Hints'}</ListGroupItemHeading>
								</ListGroupItem>
								{themeHints.reverse().map(hint =>
									<ListGroupItem
										className={cx({
											'text-dark': !themeGuessed,
											'bg-secondary': themeGuessed
										})}
										key={hint.hint}>
										<Row>
											<Col xs="auto" className="d-flex align-items-center">
												{themeGuessed ?
													<FaExclamation />
													:
													<FaQuestion />
												}
											</Col>
											<Col className=" d-flex align-items-center justify-content-center text-center">
												{hint.hint}
											</Col>
											<Col xs="auto" className="d-flex align-items-center">
												{themeGuessed ?
													<FaExclamation />
													:
													<FaQuestion />
												}
											</Col>
										</Row>
									</ListGroupItem>
								)}
							</ListGroup>
							<ListGroup>
								<ListGroupItem color="primary" className="mt-3">
									<ListGroupItemHeading>Artist {!artistGuessed && ' Hints'}</ListGroupItemHeading>
								</ListGroupItem>
								{artistHints.reverse().map(hint =>
									<ListGroupItem
										className={cx({
											'text-dark': !artistGuessed,
											'bg-secondary': artistGuessed
										})}
										key={hint.hint}>
										<Row>
											<Col xs="auto" className="d-flex align-items-center">
												{artistGuessed ?
													<FaExclamation />
													:
													<IoMdMicrophone />
												}
											</Col>
											<Col className=" d-flex align-items-center justify-content-center text-center">
												{hint.hint}
											</Col>
											<Col xs="auto" className="d-flex align-items-center">
												{artistGuessed ?
													<FaExclamation />
													:
													<IoMdMicrophone />
												}
											</Col>
										</Row>
									</ListGroupItem>
								)}
							</ListGroup>
							<ListGroup>
								<ListGroupItem className="mt-3" color="primary">
									<ListGroupItemHeading>Track {!trackGuessed && ' Hints'}</ListGroupItemHeading>
								</ListGroupItem>
								{trackHints.reverse().map((hint) =>
									<ListGroupItem
										className={cx({
											'text-dark': !trackGuessed,
											'bg-secondary': trackGuessed
										})}
										key={hint.hint}
									>
										<Row>
											<Col xs="auto" className="d-flex align-items-center">
												{trackGuessed ?
													<FaExclamation />
													:
													<GiAudioCassette />
												}
											</Col>
											<Col className=" d-flex align-items-center justify-content-center text-center">
												{hint.hint === 'ALBUM' ?
													<span><img alt="album cover" className="py-2" src={this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].albumCover} width={75} />&nbsp;
															Album Cover
												</span>
													:
													<span>
														{hint.hint}
													</span>
												}
											</Col>
											<Col xs="auto" className="d-flex align-items-center">
												{trackGuessed ?
													<FaExclamation />
													:
													<GiAudioCassette />
												}
											</Col>
										</Row>
									</ListGroupItem>
								)}
							</ListGroup>
						</div>
						<div className="full-page-slide">
							<Scorekeeper />
						</div>
						<div className="full-page-slide p-2">
							<Row>
								<Col>
									<h1>General Instructions</h1>
									<h3>Special Buttons</h3>
									<h6>Let It Play</h6>
									<p>Clicking this emote has the additional effect of popping up a notification on the DJ's screen to inform them that you would like this song to play for longer.  Abuse this feature at your own risk.</p>
									<h6>"Heart" Button</h6>
									<p>Clicking this emote has the additional effect of adding the current song to your personal playlist.  An email containing links to all of your favorite songs will be sent to you at the end of the game.</p>
									<Button type="button" color="secondary" onClick={this.props.logout}>Leave this Game</Button>
								</Col>
							</Row>
						</div>
					</SwipeableViews>
				}
				<ButtonGroup className="slide-nav">
					<Button
						className="slide-nav-buttons"
						color={this.state.index === 0 ? 'light' : 'primary'}
						onClick={() => !this.isCancelled && this.setState({ index: 0 })}
					>
						<FaRegSmileBeam />
					</Button>
					<Button
						className="slide-nav-buttons"
						color={this.state.index === 1 ? 'light' : 'primary'}
						onClick={() => !this.isCancelled && this.setState({ index: 1 })}
					>
						<GoQuestion />
					</Button>
					<Button
						className="slide-nav-buttons"
						color={this.state.index === 2 ? 'light' : 'primary'}
						onClick={() => !this.isCancelled && this.setState({ index: 2 })}
					>
						<GoDashboard />
						</Button>
					<Button
						className="slide-nav-buttons"
						color={this.state.index === 3 ? 'light' : 'primary'}
						onClick={() => !this.isCancelled && this.setState({ index: 3 })}
					>
						<GoGear />
						</Button>
				</ButtonGroup>
				<ToastContainer position="top-right" autoClose={5000} style={{ zIndex: 1999 }} />
			</div>


		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PartierMenu);