import React from 'react';
import {
	Badge,
	Input,
	Button,
	Col,
	ListGroupItemHeading,
	ListGroup,
	Row,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	ModalBody,
	Modal,
	ModalHeader,
	ModalFooter,
	Label,
	ListGroupItem,
	FormGroup
} from 'reactstrap';
import { Form, Checkbox } from 'informed';
import { WrappedText, WrappedAutosuggestWithAppend, WrappedCheckbox } from '../utilities/formElements';
import Player from '../player/Player';
import axios from 'axios';
import { FiXCircle, FiEdit } from 'react-icons/fi';
import moment from 'moment';
import { required } from '../utilities/Validation';
import { DropTarget } from 'react-dnd';
import { DnDTypes } from '../utilities/constants';


const dropTarget = {
	drop(props, monitor) {
		const item = monitor.getItem();

		switch (monitor.getItemType()) {
			case 'track_search_result':
				props.focusActiveTrack(item);
				break;
			case 'theme_search_result':
				const activeTrack = props.activeTrack.id;
				const themeTracks = item.eligibleTracks.map(el => el.id);

				if (!themeTracks.includes(activeTrack)) {
					props.updateTheme({ ...item, eligibleTracks: [...item.eligibleTracks, props.activeTrack] });
				}
				break;
			default:
				break;
		}
	}
};

const collect = (connect, monitor) => {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop(),
		itemType: monitor.getItemType(),
	}
};

class TrackDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			nameModal: {
				open: false
			},
			artistHintModal: {
				open: false
			},
			songHintModal: {
				open: false
			},
			timestampModal: {
				open: false,
				timestamp: {}
			},
			dateModal: {
				open: false
			}
		};

		this.isCancelled = false;
		this.artistHintFormApi = null;
		this.songHintFormApi = null;
		this.timestampFormApi = null;
		this.newTagApi = null;

		this.toggle = this.toggle.bind(this);
		this.updateTrack = this.updateTrack.bind(this);
		this.newTag = this.newTag.bind(this);
		this.removeTag = this.removeTag.bind(this);
		this.getSuggestedTags = this.getSuggestedTags.bind(this);
		this.removeThemeOfTrack = this.removeThemeOfTrack.bind(this);
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	toggle(modal) {
		!this.iscancelled && this.setState(prevState => ({ ...prevState, [modal]: { ...prevState[modal], open: !prevState[modal].open } }))
	}

	newTag(val) {
		axios.post('/tag', { name: val.tag, track: this.props.activeTrack._id })
			.then(res => {
				this.props.selectActiveTrack({ ...this.props.activeTrack, tag: res.data.tag });
				this.props.updateTrack({ ...this.props.activeTrack, tag: res.data.tag });
				this.newTagApi.reset();
			})
			.catch(err => window.console && console.log(err));
	}

	removeTag(tag) {
		axios.post('/tag/remove', { tag: tag, track: this.props.activeTrack._id })
			.then(res => {
				this.props.selectActiveTrack({ ...this.props.activeTrack, tag: res.data.tag });
				this.props.updateTrack({ ...this.props.activeTrack, tag: res.data.tag });
			})
			.catch(err => window.console && console.log(err));
	}

	updateTrack(update) {
		const promises = [];

		promises.push(axios.post('/track/update', update));
		promises.push(axios.get('/theme/of-track/' + update._id));

		axios.all(promises)
			.then(res => {
				this.props.selectActiveTrack({ ...res[0].data, themes: res[1].data });
				this.props.updateTrack({ ...res[0].data, themes: res[1].data });
			})
			.catch(err => window.console && console.error(err));
	}

	removeThemeOfTrack(theme) {

		axios.post('/theme/remove-track', { theme: theme, track: this.props.activeTrack._id })
			.then(res => {
				axios.get('/theme/of-track/' + this.props.activeTrack._id)
					.then(themeRes => {
						this.props.selectActiveTrack({ ...this.props.activeTrack, themes: themeRes.data });
						this.props.updateTrack({ ...this.props.activeTrack, themes: themeRes.data });
					})
					.catch(err => window.console && console.log(err));
				window.console && console.log(res.data);
			})
			.catch(err => window.console && console.log(err));
	}


	getSuggestedTags(val, cb) {
		if (val) {
			axios.get('/tag/' + encodeURIComponent(val))
				.then(res => {
					cb(res.data);
				})
				.catch(err => window.console && console.log(err));
		}
		else cb([]);
	}

	render() {
		return (this.props.connectDropTarget(
			<div>
				<Row>
					<Col md="6">
						{(this.props.playerState.item && this.props.playerState.item.id === this.props.activeTrack.id) || !this.props.playerState.device ?
							<React.Fragment>
								<Player />
							</React.Fragment>
							:
							<Button color="secondary" outline onClick={() => this.props.playTrack(this.props.activeTrack.id)} >Play This Track</Button>
						}
					</Col>
					<Col md="6">
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="speedEligible"
								checked={this.props.activeTrack.speedEligible}
								onChange={(e) => this.updateTrack({ _id: this.props.activeTrack._id, speedEligible: e.target.checked })}
							/>
							<label className="custom-control-label" htmlFor="speedEligible">Speed Eligible</label>
						</div>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="goodSong"
								checked={this.props.activeTrack.goodSong}
								onChange={(e) => this.updateTrack({ _id: this.props.activeTrack._id, goodSong: e.target.checked })}
							/>
							<label className="custom-control-label" htmlFor="goodSong">Party Song</label>
						</div>
						<div className="custom-control custom-switch">
							<input
								type="checkbox"
								className="custom-control-input"
								id="wellKnown"
								checked={this.props.activeTrack.wellKnown}
								onChange={(e) => this.updateTrack({ _id: this.props.activeTrack._id, wellKnown: e.target.checked })}
							/>
							<label className="custom-control-label" htmlFor="wellKnown">Well Known</label>
						</div>
						<Row className="pt-2">
							<Col>
								{this.props.activeTrack.customReleaseDate ?
									<span className="lead">Release Date {this.props.activeTrack.customReleaseDate}</span>
									:
									this.props.activeTrack.releaseDate ?
										<span className="lead">Release Date {moment.utc(this.props.activeTrack.releaseDate).format('YYYY')} <Badge>default</Badge></span>
										:
										<span className="lead">No release date available</span>
								}
							</Col>
							<Col xs="auto" className="px-0">
								<Button color="link" onClick={() => this.setState({ dateModal: { open: true } })} ><FiEdit /></Button>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<ListGroup className="py-2">
							<ListGroupItemHeading>Tags</ListGroupItemHeading>
							{this.props.activeTrack.tag && this.props.activeTrack.tag.map(tag =>
								<ListGroupItem key={tag._id}>
									<Row>
										<Col>
											{tag.name}
										</Col>
										<Col xs="auto">
											<FiXCircle className="text-danger" onClick={() => this.removeTag(tag._id)} />
										</Col>
									</Row>
								</ListGroupItem>
							)}
							<Form id="newTag" getApi={api => this.newTagApi = api} onSubmit={this.newTag}>
								{({ formState }) =>
									<WrappedAutosuggestWithAppend
										field="tag"
										getSuggestions={this.getSuggestedTags}
										onClick={() => this.newTag(formState.values)}
										placeholder="add new tag"
										validate={required}
									/>
								}
							</Form>
						</ListGroup>
					</Col>
					<Col md="6">
						<ListGroup className="py-3">
							<ListGroupItemHeading className="mt-2">
								Timestamps
									</ListGroupItemHeading>
							{this.props.activeTrack.timeStamps && this.props.activeTrack.timeStamps.map(timestamp =>
								<button
									key={timestamp._id}
									type="button"
									className="list-group-item list-group-item-action"
									style={{ backgroundColor: timestamp.default ? '#e1e1e1' : 'white' }}
									onClick={() => this.setState({ timestampModal: { timestamp: timestamp, open: true } })}
								>
									<Row>
										<Col xs="7">
											{timestamp.name}
										</Col>
										<Col xs="3">
											{Math.round(timestamp.time / 1000)}s
															</Col>
										<Col xs="1">
											<FiXCircle className="text-danger" onClick={(e) => {
												const newStamps = [];
												for (let i = 0; i < this.props.activeTrack.timeStamps.length; i++) {
													if (timestamp._id !== this.props.activeTrack.timeStamps[i]._id) {
														newStamps.push(this.props.activeTrack.timeStamps[i]);
													}
												}
												this.updateTrack({ ...this.props.activeTrack, timeStamps: newStamps });
												e.stopPropagation();
											}} />
										</Col>
									</Row>
								</button>
							)}
							<Form id="newTimestamp" getApi={api => this.timestampFormApi = api} onSubmit={val => { this.updateTrack({ ...this.props.activeTrack, timeStamps: [...this.props.activeTrack.timeStamps, { name: val.name, time: Number(val.time) * 1000, default: val.default }] }); this.timestampFormApi.reset(); }} >
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<Checkbox field="default" />
										</InputGroupText>
									</InputGroupAddon>
									<WrappedText
										placeholder="name"
										field="name"
										validate={required}
									/>
									<WrappedText
										placeholder="seconds"
										type="number"
										field="time"
										validate={required}
									/>
									<InputGroupAddon addonType="append"><Button type="submit">+</Button></InputGroupAddon>
								</InputGroup>
							</Form>
						</ListGroup>
					</Col>
				</Row>
				<Row className="pt-3">
					<Col md="4">
						<ListGroupItemHeading>Artist Hints</ListGroupItemHeading>
						<ListGroup>
							{this.props.activeTrack.artistHints.map((hint, index) =>
								<button
									type="button"
									key={index}
									className="list-group-item list-group-item-action"
									onClick={() => this.setState({ artistHintModal: { hint: hint, index: index, open: true } })}
								>
									<Row>
										<Col>
											{hint}
										</Col>
										<Col xs="auto">
											<FiXCircle className="text-danger" onClick={(e) => {
												const newHints = [];
												for (let i = 0; i < this.props.activeTrack.artistHints.length; i++) {
													if (i !== index) {
														newHints.push(this.props.activeTrack.artistHints[i]);
													}
												}
												this.updateTrack({ ...this.props.activeTrack, artistHints: newHints });
												e.stopPropagation();
											}} />
										</Col>
									</Row>
								</button>
							)}
						</ListGroup>
						<Form id="addArtistHint" getApi={api => this.artistHintFormApi = api} onSubmit={val => { this.updateTrack({ ...this.props.activeTrack, artistHints: [...this.props.activeTrack.artistHints, val.hint] }); this.artistHintFormApi.reset(); }}>
							<InputGroup>
								<WrappedText
									field="hint"
									validate={required}
									placeholder="add new artist hint"
								/>
								<InputGroupAddon addonType="append">
									<Button type="submit" >+</Button>
								</InputGroupAddon>
							</InputGroup>
						</Form>
					</Col>
					<Col md="4">
						<ListGroupItemHeading>Track Hints</ListGroupItemHeading>
						<ListGroup>
							{this.props.activeTrack.songHints.map((hint, index) =>
								<button
									type="button"
									key={index}
									className="list-group-item list-group-item-action"
									onClick={() => this.setState({ songHintModal: { hint: hint, index: index, open: true } })}
								>
									<Row>
										<Col>
											{hint}
										</Col>
										<Col xs="auto">
											<FiXCircle className="text-danger" onClick={(e) => {
												const newHints = [];
												for (let i = 0; i < this.props.activeTrack.songHints.length; i++) {
													if (i !== index) {
														newHints.push(this.props.activeTrack.songHints[i]);
													}
												}
												this.updateTrack({ ...this.props.activeTrack, songHints: newHints });
												e.stopPropagation();
											}} />
										</Col>
									</Row>
								</button>
							)}
						</ListGroup>
						<Form id="addSongHint" getApi={api => this.songHintFormApi = api} onSubmit={val => { this.updateTrack({ ...this.props.activeTrack, songHints: [...this.props.activeTrack.songHints, val.hint] }); this.songHintFormApi.reset(); }}>
							<InputGroup>
								<WrappedText
									field="hint"
									validate={required}
									placeholder="add new song hint"
								/>
								<InputGroupAddon addonType="append">
									<Button type="submit">+</Button>
								</InputGroupAddon>
							</InputGroup>
						</Form>
					</Col>
					<Col md="4">
						<ListGroupItemHeading>Associated Themes</ListGroupItemHeading>
						<ListGroup>
							{this.props.activeTrack.themes.map(theme =>
								<button
									type="button"
									key={theme._id}
									className="list-group-item list-group-item-action"
									onClick={() => {
										axios.get('/theme?search=' + theme._id)
											.then(res => {
												this.props.addThemes(res.data);
												this.props.selectActiveTheme(res.data[0]);
											})
											.catch(err => window.console && console.log(err));
									}}
								>
									<Row>
										<Col>
											{theme.theme}
										</Col>
										<Col xs="auto">
											<FiXCircle className="text-danger" onClick={(e) => {
												this.removeThemeOfTrack(theme._id);
												e.stopPropagation();
											}} />
										</Col>
									</Row>
								</button>
							)}
						</ListGroup>
						{this.props.activeTheme._id && !this.props.activeTrack.themes.find(el => el._id === this.props.activeTheme._id) &&
							<InputGroup>
								<Input
									placeholder={`add "${this.props.activeTheme.theme}"`}
									disabled
								/>
								<InputGroupAddon addonType="append">
									<Button type="button" onClick={() => {
										this.props.updateTheme({ ...this.props.activeTheme, eligibleTracks: [...this.props.activeTheme.eligibleTracks, this.props.activeTrack] });
									}} >+</Button>
								</InputGroupAddon>
							</InputGroup>
						}
					</Col>
				</Row>
				<Modal isOpen={this.state.nameModal.open} toggle={() => this.toggle('nameModal')} >
					<Form id="customName" onSubmit={val => { this.updateTrack({ ...this.props.activeTrack, name: val.name }); this.toggle('nameModal') }} >
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('nameModal')}>Custom Track Name</ModalHeader>
								<ModalBody>
									<WrappedText
										field="name"
										validate={required}
										initialValue={this.props.activeTrack.name}
									/>
									<div className='invalid-feedback'>{formState.errors.name}</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="" color="secondary">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
				<Modal isOpen={this.state.artistHintModal.open} toggle={() => this.toggle('artistHintModal')} >
					<Form id="updateHint" onSubmit={val => {
						const newHints = [];
						for (let i = 0; i < this.props.activeTrack.artistHints.length; i++) {
							if (i !== this.state.artistHintModal.index) {
								newHints.push(this.props.activeTrack.artistHints[i]);
							} else {
								newHints.push(val.hint);
							}
						}
						this.updateTrack({ ...this.props.activeTrack, artistHints: newHints });
						this.toggle('artistHintModal');
					}}
					>
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('artistHintModal')}>Update Artist Hint</ModalHeader>
								<ModalBody>
									<WrappedText
										field="hint"
										validate={required}
										initialValue={this.props.activeTrack.artistHints[this.state.artistHintModal.index]}
									/>
									<div className='invalid-feedback'>{formState.errors.hint}</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="button" onClick={() => this.toggle('artistHintModal')} color="secondary">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
				<Modal isOpen={this.state.songHintModal.open} toggle={() => this.toggle('songHintModal')} >
					<Form id="updateSongHint" onSubmit={val => {
						const newHints = [];
						for (let i = 0; i < this.props.activeTrack.songHints.length; i++) {
							if (i !== this.state.songHintModal.index) {
								newHints.push(this.props.activeTrack.songHints[i]);
							} else {
								newHints.push(val.hint);
							}
						}
						this.updateTrack({ ...this.props.activeTrack, songHints: newHints });
						this.toggle('songHintModal');
					}} >
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('songHintModal')}>Update Song Hint</ModalHeader>
								<ModalBody>
									<WrappedText
										field="hint"
										validate={required}
										initialValue={this.props.activeTrack.songHints[this.state.songHintModal.index]}
									/>
									<div className='invalid-feedback'>{formState.errors.hint}</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="button" onClick={() => this.toggle('songHintModal')} color="secondary">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
				<Modal isOpen={this.state.timestampModal.open} toggle={() => this.toggle('timestampModal')} >
					<Form id="updateTimestamp" onSubmit={val => {
						const stamps = this.props.activeTrack.timeStamps.map(stamp => {
							if (stamp._id === this.state.timestampModal.timestamp._id) {
								return { ...this.state.timestampModal.timestamp, name: val.name, time: val.time * 1000, default: val.default }
							} else return stamp;
						});
						this.updateTrack({ ...this.props.activeTrack, timeStamps: stamps });
						this.toggle('timestampModal');
					}} >
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('timestampModal')}>Update Timestamp</ModalHeader>
								<ModalBody>
									<Row>
										<Col md="6">
											<Label htmlFor="name">Name<span className="text-danger">*</span></Label>
											<WrappedText
												field="name"
												validate={required}
												initialValue={this.state.timestampModal.timestamp.name}
											/>
											<div className='invalid-feedback'>{formState.errors.name}</div>
										</Col>
										<Col md="6">
											<Label htmlFor="time">Time<span className="text-danger">*</span></Label>
											<InputGroup>
												<WrappedText
													field="time"
													validate={required}
													initialValue={Math.round(this.state.timestampModal.timestamp.time / 1000)}

												/>
												<InputGroupAddon addonType="append">s</InputGroupAddon>
											</InputGroup>
											<div className='invalid-feedback'>{formState.errors.time}</div>
										</Col>
									</Row>
									<FormGroup check className="pt-2">
										<Label check>
											<WrappedCheckbox
												field='default'
												initialValue={this.state.timestampModal.timestamp.default}
											/> Mark this timestamp as default start time</Label>
									</FormGroup>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="button" onClick={() => this.toggle('timestampModal')} color="secondary">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
				<Modal isOpen={this.state.dateModal.open} toggle={() => this.toggle('dateModal')} >
					<Form id="updateDate" onSubmit={val => {
						this.updateTrack({ ...this.props.activeTrack, customReleaseDate: val.date });
						this.toggle('dateModal');
					}} >
						{({ formState }) =>
							<React.Fragment>
								<ModalHeader toggle={() => this.toggle('dateModal')}>Update Release Date</ModalHeader>
								<ModalBody>
									<WrappedText
										field="date"
										validate={required}
										initialValue={this.props.activeTrack.customReleaseDate ? this.props.activeTrack.customReleaseDate : moment.utc(this.props.activeTrack.releaseDate).format('YYYY')}
									/>
									<div className='invalid-feedback'>{formState.errors.date}</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" color="primary">Update</Button>
									<Button type="button" onClick={() => this.toggle('dateModal')} color="secondary">Cancel</Button>
								</ModalFooter>
							</React.Fragment>
						}
					</Form>
				</Modal>
			</div>
		));
	}
}

export default DropTarget([DnDTypes.TRACK_SEARCH_RESULT, DnDTypes.THEME_SEARCH_RESULT], dropTarget, collect)(TrackDetail);