import React from 'react';
import {
	ListGroupItem,
	Row,
	Col,
	Button
} from 'reactstrap';
import { FiXCircle, FiPlayCircle, FiPauseCircle } from 'react-icons/fi';
import { DragSource } from 'react-dnd';
import { DnDTypes } from '../utilities/constants';
import { connect } from 'react-redux';
import axios from 'axios';


const listItemSource = {
	beginDrag(props) {
		return props.track ? props.track : props.theme;
	},
};

const collect = (connect, monitor) => {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	}
}

const mapStateToProps = state => ({
	playerState: state.playerState
});

class TrackListItem extends React.Component {
	constructor(props) {
		super(props);

		this.isCancelled = false;

		this.remove = this.remove.bind(this);
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	remove(e) {
		const track = this.props.track;

		this.props.removeTrack(track._id);
		e.stopPropagation();
	}

	play(e) {
		axios.get('/spotify/select-track/' + this.props.track.id)
			.then(() => null)
			.catch(err => window.console && console.log(err));
		e.stopPropagation();
	}

	pause(e) {
		axios.get('/spotify/play-pause')
			.then(() => null)
			.catch(err => window.console && console.log(err));
		e.stopPropagation();

	}

	render() {
		const track = this.props.track;

		return (
			this.props.connectDragSource(
				<div>
					<ListGroupItem href="#" className="list-group-item-action" style={{ cursor: 'pointer', backgroundColor: this.props.bgColor }} onClick={() => this.props.focusActiveTrack(track)}>
						<Row>
							<Col xs={'auto'} className="p-0 d-flex align-items-center">
								<img src={track.albumCover} alt="album cover" height={50} />
							</Col>
							<Col>
								{track.name}<br />
								<small><em>{track.artist.map(el => el.name).join(', ')}</em></small>
							</Col>
							<Col xs='auto' className="d-flex align-items-center p-1">
								<Button color="link" className="p-0" onClick={this.remove}><FiXCircle className="text-danger" style={{ fontSize: '1.5em' }} /></Button>
								{this.props.playerState.is_playing && this.props.playerState.item.id === this.props.track.id ?
									<Button color="link" className="p-0" onClick={this.pause}><FiPauseCircle className="text-primary" style={{ fontSize: '1.5em' }} /></Button>
									:
									<Button color="link" className="p-0" onClick={this.play}><FiPlayCircle className="text-primary" style={{ fontSize: '1.5em' }} /></Button>
								}
							</Col>
						</Row>
					</ListGroupItem>
				</div>
			));
	}
}


class ThemeListItem extends React.Component {
	constructor(props) {
		super(props);

		this.isCancelled = false;

		this.remove = this.remove.bind(this);
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	remove(e) {
		const theme = this.props.theme;
		this.props.removeTheme(theme._id);
		e.stopPropagation();
	}

	render() {
		const theme = this.props.theme;

		return (this.props.connectDragSource(
			<div>
				<ListGroupItem key={theme._id} href="#" className="list-group-item-action" style={{ cursor: 'pointer', backgroundColor: this.props.bgColor }} onClick={() => this.props.focusActiveTheme(theme)}>
					<Row>
						<Col>
							{theme.theme} ({theme.eligibleTracks.length} tracks)
						</Col>
						<Col xs='auto' className="d-flex align-items-center p-1">
							<Button color="link" className="p-0" onClick={this.remove}><FiXCircle className="text-danger" style={{ fontSize: '1.5em' }} /></Button>
						</Col>
					</Row>
				</ListGroupItem>
			</div>
		));
	}
}

const wrappedTrackListItem = DragSource(DnDTypes.TRACK_SEARCH_RESULT, listItemSource, collect)(connect(mapStateToProps)(TrackListItem));
const wrappedThemeListItem = DragSource(DnDTypes.THEME_SEARCH_RESULT, listItemSource, collect)(ThemeListItem);

export { wrappedTrackListItem as TrackListItem };
export { wrappedThemeListItem as ThemeListItem };
