import React from 'react';
import { connect } from 'react-redux';
import { ListGroupItem, Col, Row } from 'reactstrap';
import posed, { PoseGroup } from 'react-pose';
import ReactTimeout from 'react-timeout';
import SplitText from 'react-pose-text';

const Track = posed.div({
	enter: {
		opacity: 1,
		delay: 200,
		transition: {
			duration: 200
		}
	},
	exit: { opacity: 0 },
	flip: {
		transition: {
			velocity: 20,
		}
	}
});

const charPoses = {
	enter: {
		opacity: 1,
		delay: 300
	},
	exit: { opacity: 0 }
};

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

class TrackDisplay extends React.Component {
	render() {
		const tracks = this.props.gameStatus.round.tracks.filter((track, index) => {
			if (index < this.props.gameStatus.round.trackIndex || (index === this.props.gameStatus.round.trackIndex && track.trackGuessed && track.artistGuessed)) {
				return true;
			} else return false;
		});

		return (
			<PoseGroup className="list-group">
				{tracks.reverse().map(track =>
					<Track key={track._id}>
						<ListGroupItem className="bg-gradient-theme-left my-2 border-0">
							<Row>
								<Col xs="auto" className="d-flex align-items-center">
									{track.albumCover &&
										<img src={track.albumCover} width="125" alt="album cover" />
									}
								</Col>
								<Col className="d-flex flex-column justify-content-center">
									<h1 className="neucha">
										<SplitText charPoses={charPoses}>
											{track.name}
										</SplitText>
									</h1>
									<h2 className="neucha">
										<em>
											<SplitText charPoses={charPoses}>
												{track.artist}
											</SplitText>
										</em>
									</h2>
								</Col>
							</Row>
						</ListGroupItem>
					</Track>
				)}
			</PoseGroup>
		);
	}
}

export default connect(mapStateToProps)(ReactTimeout(TrackDisplay));