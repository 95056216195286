export const uppercase = (val) => {
	if (!val) return '';
	return (String(val).toUpperCase());
};

export const alphaOnly = val => {
	if (!val) return '';

	return val.replace(/[^a-zA-Z]+/g, '');
}
