import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import posed, { PoseGroup } from 'react-pose';
import ReactTimeout from 'react-timeout';

const TeamEntry = posed.div({
	flip: {
		delay: 600,
	}
});

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

class Scoreboard extends React.Component {
	constructor(props) {
		super(props);

		const newScore = {};

		this.props.gameStatus.teams.forEach(team => {
			newScore[team._id] = { new: false, score: team.score };
		});

		this.state = {
			newScore: newScore
		};

		this.isCancelled = false;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	componentDidUpdate(prevProps) {
		this.props.gameStatus.teams.forEach(team => {
			const oldScore = prevProps.gameStatus.teams.find(el => el._id === team._id).score;

			if (team.score !== oldScore) {
				!this.isCancelled && this.setState(prevState => ({ newScore: { ...prevState.newScore, [team._id]: { new: true, score: team.score } } }));

				this.props.setTimeout(() => {
					!this.isCancelled && this.setState(prevState => ({ newScore: { ...prevState.newScore, [team._id]: { new: false, score: team.score } } }));

				}, 600);
			}
		});
	}

	render() {
		return (
			<PoseGroup>
				{this.props.gameStatus.teams.sort((a, b) => a.score > b.score ? -1 : 1).map(team =>
					<TeamEntry key={team._id} className="text-right">
						<h1 className="neucha">{team.name} <Badge style={{ display: 'inline-block', width: 75 }} color={this.state.newScore[team._id].new ? 'light' : 'secondary'}>
							{this.state.newScore[team._id].score}
						</Badge></h1>
					</TeamEntry>
				)}
			</PoseGroup>
		);
	}
}

export default connect(mapStateToProps)(ReactTimeout(Scoreboard));