import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import ReactTimeout from 'react-timeout';

const Avatar = posed.div({
	stand: {
		width: 100,
		height: 100,
	},
	sit: {
		width: 100,
		height: 50
	}
});

const Emote = posed.div({
	present: {
		width: 100,
		height: 100,
		x: 90,
		y: -90,
		position: 'absolute',
		opacity: 1,
		transition: {
			duration: 400
		}
	},
	absent: {
		width: 100,
		height: 100,
		x: 90,
		y: -90,
		position: 'absolute',
		opacity: 0,
		transition: {
			duration: 400
		}
	}
});

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

class PeanutGallery extends React.Component {
	constructor(props) {
		super(props);

		const poses = {};
		const emotes = {};
		const emoteImages = {};

		for (let i = 0; i < this.props.gameStatus.players.length; i++) {
			poses[this.props.gameStatus.players[i]._id] = 'sit';
			emotes[this.props.gameStatus.players[i]._id] = 'absent';
			emoteImages[this.props.gameStatus.players[i]._id] = null;
		}

		this.state = {
			pose: poses,
			emotes: emotes,
			emoteImages: emoteImages
		}

		this.isCancelled = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.gameStatus.players.filter(el => el.avatar).length !== this.props.gameStatus.players.filter(el => el.avatar).length) {
			this.props.gameStatus.players.forEach(player => {
				if (!this.state.pose[player._id] || !this.state.emotes[player._id]) {
					!this.isCancelled && this.setState(prevState => ({
						pose: { ...prevState.pose, [player._id]: 'sit' },
						emotes: { ...prevState.emotes, [player._id]: 'absent' }
					}));
				}
			});
		}

		const oldEmotes = prevProps.gameStatus.players.map(el => el.emote);
		const newEmotes = this.props.gameStatus.players.map(el => el.emote);

		for (let i = 0; i < newEmotes.length; i++) {
			const player = this.props.gameStatus.players[i]._id;
			const emote = newEmotes[i];

			if (oldEmotes[i] !== newEmotes[i] && newEmotes[i] && this.state.pose[player] === 'sit') {

				!this.isCancelled && this.setState(prevState => ({
					pose: { ...prevState.pose, [player]: 'stand' },
					emotes: { ...prevState.emotes, [player]: 'present' },
					emoteImages: { ...prevState.emoteImages, [player]: emote },
				}), () => {
					this.props.setTimeout(() => {
						!this.isCancelled && this.setState(prevState => ({
							pose: { ...prevState.pose, [player]: 'sit' },
							emotes: { ...prevState.emotes, [player]: 'absent' },
							emoteImages: { ...prevState.emoteImages, [player]: null },
						}));
					}, 2000);
				});

			}
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	render() {
		return (
			<div className="h-100 w-100">
				<div className="d-flex flex-row align-items-end h-100">
					{this.props.gameStatus.players.filter(el => el.avatar).map(player =>
						<div key={player._id} className="d-flex flex-column">
							{this.state.emoteImages[player._id] &&
									<Emote className="mx-3 flex-0" pose={this.state.emotes[player._id]} initialPose="absent" >
										<img src={require(`../../assets/img/emotes/display${this.state.emoteImages[player._id]}.png`)} alt={this.state.emoteImages[player._id]} width={100} />
									</Emote>

							}
							<Avatar className="mx-3 flex-0" pose={this.state.pose[player._id]} >
								<img src={require(`../../assets/img/avatars/${player.avatar}.png`)} alt={this.props.gameStatus.players.avatar} width={100} />
							</Avatar>
						</div>
					)}
				</div>
				<div className="text-right" style={{ position: 'absolute', bottom: 0, right: 25 }}>
					<Link to="/"><Button type="button" color="secondary" className="my-2">Home</Button></Link>
					<h3 className="neucha">Room Code: {this.props.gameStatus.code}</h3>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ReactTimeout(PeanutGallery));