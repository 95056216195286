import * as ActionTypes from './actionTypes';

export const reducers = (state = {
	loggedIn: false,
	loggingIn: false,
	user: {},
	loginError: null,
	playerState: {},
	activeTracks: [],
	activeThemes: [],
	activeTrack: {},
	activeTheme: {},
	gameState: {}, // this reflects the game object itself (rounds an the like)--used by DJ
	gameStatus: {}, // this reflects the state of an active game for use with interactivity
}, action) => {
	switch (action.type) {
		case ActionTypes.LOGIN_SUCCESS:
			return { ...state, loggedIn: true, user: action.payload, loggingIn: false, loginError: null };
		case ActionTypes.LOGIN_FAILURE:
			return { ...state, loggedIn: false, user: {}, loggingIn: false, loginError: action.payload };
		case ActionTypes.LOGGING_IN:
			return { ...state, loggedIn: false, loggingIn: true, user: {}, loginError: null };
		case ActionTypes.LOGOUT_SUCCESS:
			return { ...state, loggedIn: false, user: {}, loggingIn: false, loginError: null };
		case ActionTypes.UPDATE_PLAYER_STATE:
			return { ...state, playerState: action.payload };
		case ActionTypes.ADD_TRACKS:
			return { ...state, activeTracks: [...state.activeTracks, ...action.payload.filter(el => !state.activeTracks.map(el => el._id).includes(el._id))] };
		case ActionTypes.REMOVE_TRACK:
			return { ...state, activeTracks: state.activeTracks.filter(el => el._id !== action.payload) };
		case ActionTypes.REMOVE_ALL_TRACK:
			return { ...state, activeTracks: [] };
		case ActionTypes.SELECT_ACTIVE_TRACK:
			return { ...state, activeTrack: action.payload };
		case ActionTypes.SELECT_ACTIVE_THEME:
			return { ...state, activeTheme: action.payload };
		case ActionTypes.ADD_THEMES:
			return { ...state, activeThemes: [...state.activeThemes, ...action.payload.filter(el => !state.activeThemes.map(el => el._id).includes(el._id))] };
		case ActionTypes.REMOVE_THEME:
			return { ...state, activeThemes: state.activeThemes.filter(el => el._id !== action.payload) };
		case ActionTypes.REMOVE_ALL_THEMES:
			return { ...state, activeThemes: [] };
		case ActionTypes.UPDATE_TRACK:
			return { ...state, activeTracks: state.activeTracks.map(track => track._id === action.payload._id ? action.payload : track) };
		case ActionTypes.UPDATE_THEME:
			return { ...state, activeThemes: state.activeThemes.map(theme => theme._id === action.payload._id ? action.payload : theme) };
		case ActionTypes.SET_GAME:
			return { ...state, gameState: action.payload };
		case ActionTypes.ADD_ROUND:
			return { ...state, gameState: {...state.gameState, rounds: [...state.gameState.rounds, action.payload]} };
		case ActionTypes.UPDATE_GAME_STATUS:
			return { ...state, gameStatus: action.payload ? {...state.gameStatus, ...action.payload} : {} }
		default:
			return state;
	}
}