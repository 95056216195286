import React from 'react';
import { asField } from 'informed';
import {
	Input,
	InputGroup,
	InputGroupAddon,
	Button
} from 'reactstrap';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';

export const WrappedText = asField(({ fieldState, fieldApi, ...props }) => {

	const { setValue, setTouched } = fieldApi;

	const onChange = e => {
		if (e.target.value) {
			setValue(e.target.value);
		} else {
			setValue('');
		}
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}

	const onBlur = e => {
		setTouched();
		if (props.onBlur) {
			props.onBlur(e.target.value);
		}
	}

	return (
		<Input
			onChange={onChange}
			onBlur={onBlur}
			className={classnames({
				'is-invalid': fieldState.error
			})}
			rows={props.rows}
			type={props.type}
			placeholder={props.placeholder}
			disabled={props.disabled}
			value={fieldState.value ? fieldState.value : ''}
		/>
	);
});

// Teach Autosuggest how to calculate suggestions for any given input value.


// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
	<span>
		{suggestion.name}
	</span>
);

const theme = {
	suggestionsList: 'autosuggest-suggestions-list',
	suggestion: 'autosuggest-suggestion',
	suggestionHighlighted: 'autosuggest-suggestion-highlighted',
	suggestionsContainerOpen: 'autosuggest-suggestions-container-open',
	suggestionsContainer: 'autosuggest-suggestions-container',
	container: 'autosuggest-container'
};



class AutoSuggestWithAppend extends React.Component {
	constructor() {
		super();

		// Autosuggest is a controlled component.
		// This means that you need to provide an input value
		// and an onChange handler that updates this value (see below).
		// Suggestions also need to be provided to the Autosuggest,
		// and they are initially empty because the Autosuggest is closed.
		this.state = {
			suggestions: []
		};

		this.renderInputComponent = this.renderInputComponent.bind(this);
		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
	}

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	onSuggestionsFetchRequested = ({ value }) => {

		this.props.getSuggestions(value, res => {
			this.setState({
				suggestions: res
			});
		});
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

	renderInputComponent = inputProps => (
		<InputGroup>
			<Input {...inputProps} />
			<InputGroupAddon addonType="append"><Button type="button" onClick={() => this.props.onClick(this.props.value)}>+</Button></InputGroupAddon>
			<div className='invalid-feedback'>{this.props.invalid}</div>
		</InputGroup>
	)

	render() {
		const { suggestions } = this.state;
		const { value, onChange, onBlur, placeholder } = this.props;

		// Autosuggest will pass through all these props to the input.
		const inputProps = {
			placeholder: placeholder,
			value,
			onChange,
			onBlur,
			className: classnames({ 'form-control': true, 'is-invalid': this.props.invalid }),
		};

		// Finally, render it!
		return (
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
				renderInputComponent={this.renderInputComponent}
				theme={theme}
				onSuggestionSelected={this.props.onSuggestionSelected}
			/>
		);
	}
}

export const WrappedAutosuggestWithAppend = asField(({ fieldState, fieldApi, ...props }) => {

	const { setValue, setTouched } = fieldApi;

	const onChange = e => {
		if (e.target.value) {
			setValue(e.target.value);
		} else {
			setValue('');
		}
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}

	const onBlur = e => {
		setTouched();

		if (props.onBlur) {
			props.onBlur(e.target.value);
		}
	}

	const onSuggestionSelected = (e, { suggestion }) => {
		setValue(suggestion.name);
	}

	return (
		<AutoSuggestWithAppend
			onChange={onChange}
			onBlur={onBlur}
			value={fieldState.value ? fieldState.value : ''}
			getSuggestions={props.getSuggestions}
			onClick={props.onClick}
			placeholder={props.placeholder}
			invalid={fieldState.error}
			onSuggestionSelected={onSuggestionSelected}
		/>
	);
});

export const WrappedCheckbox = asField(({ fieldState, fieldApi, ...props }) => {

	const { setValue, setTouched } = fieldApi;

	const onChange = e => {
		if (e.target.checked) {
			setValue(e.target.checked);
		} else {
			setValue(false);
		}
		if (props.onChange) {
			props.onChange(e.target.checked);
		}
	}

	const onBlur = e => {
		setTouched();
		if (props.onBlur) {
			props.onBlur(e.target.value);
		}
	}

	return (
		<Input
			type="checkbox"
			onChange={onChange}
			onBlur={onBlur}
			className={classnames({
				'is-invalid': fieldState.error
			})}
			checked={fieldState.value || false}
		/>
	);
});
