import React from 'react';
import {
	Container,
	Row,
	Col,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	Button
} from 'reactstrap';
import { WrappedText } from '../utilities/formElements';
import { alphaOnly, uppercase } from '../utilities/Mask';
import { required } from '../utilities/Validation';
import { Form } from 'informed';
import { connect } from 'react-redux';
import { subscribeToGame } from '../../redux/actionCreators';
import logo from '../../assets/img/logo/tmglogo.png';
import countdownGif from '../../assets/img/gifs/dj.gif';
import MainDisplay from './MainDisplay';

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

const mapDispatchToProps = dispatch => ({
	subscribeToGame: (code, cb) => dispatch(subscribeToGame(code, cb)),
});

class Display extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			code: null,
			page: 'initial'
		};

		this.joinGame = this.joinGame.bind(this);

		this.isCancelled = false;
		this.codeApi = null;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	componentDidMount() {
		let page;
		const code = this.props.gameStatus ? this.props.gameStatus.code : null;
		if (!code) page = 'initial';
		else if (this.props.gameStatus.started === false) page = 'waiting';
		else if (this.props.gameStatus.loading) page = 'countdown';
		else page = null;

		!this.isCancelled && this.setState({ code, page });
	}

	componentDidUpdate(prevProps, prevState) {
		let page;
		const code = this.props.gameStatus ? this.props.gameStatus.code : null;
		if (!code) page = 'initial';
		else if (this.props.gameStatus.started === false) page = 'waiting';
		else if (this.props.gameStatus.loading) page = 'countdown';
		else page = null;

		if (code !== this.state.code) !this.isCancelled && this.setState({ code });

		if (page !== prevState.page) !this.isCancelled && this.setState({ page });
		if (page !== prevState.page) !this.isCancelled && this.setState({ page });

		// manage bad game code
		if (prevProps.gameStatus && this.props.gameStatus === false) {
			!this.isCancelled && this.setState({ error: 'Sorry, that code is not valid.  Please try again.' });
		}

		// set gameCode once logged in
		if (prevProps.gameStatus && !prevProps.gameStatus.code && this.props.gameStatus && this.props.gameStatus.code) {
			!this.isCancelled && this.setState({ code: this.props.gameStatus.code });
		}
	}

	joinGame(val) {
		this.props.subscribeToGame(val.code, res => {
			if (!res) {
				!this.isCancelled && this.setState({ error: 'invalid room code' });
			} else {
				!this.isCancelled && this.setState({ error: null });
			}
		});
	}

	render() {

		const pages = (state) => {
			switch (state) {
				case 'initial':
					return (
						<Row className="h-75">
							<Col className="d-flex flex-column align-items-center justify-content-center">
								<h1 className="neucha">Welcome To</h1>
								<img src={logo} alt="logo" className="w-75" />
								<Form id="code" onSubmit={this.joinGame} getApi={api => this.codeApi = api}>
									<FormGroup>
										<InputGroup>
											<WrappedText
												id="code"
												placeholder="room code"
												field="code"
												validate={required}
												mask={val => alphaOnly(uppercase(val))}
											/>
											<InputGroupAddon addonType="append">
												<Button type="submit" color="secondary">></Button>
											</InputGroupAddon>
										</InputGroup>
										<small className="text-danger">{this.state.error}</small>
									</FormGroup>
								</Form>
							</Col>
						</Row>
					);
				case 'waiting':
					return (
						<Row className="h-100">
							<Col className="d-flex flex-column h-100 align-items-center justify-content-center">
								<h1 className="neucha">Welcome To</ h1>
								<img src={logo} alt="logo" className="w-75" />
								<h3 className="neucha">we'll be starting in a moment</h3>
								<h1 className="neucha display-4">Room Code: {this.props.gameStatus.code}</h1>
							</Col>
						</Row>
					);
				case 'countdown':
					return (
						<Row className="h-100">
							<Col className="d-flex flex-column align-items-center justify-content-center">
								<img src={countdownGif} alt="countdown" width={200} />
							</Col>
						</Row>
					);
				default:
					return (
						<MainDisplay />
					);
			}
		};

		return (
			<Container fluid className="h-100 w-100 bg-primary p-0 text-light" style={{ overflow: 'hidden', position: 'relative' }}>
				{pages(this.state.page)}
			</Container>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Display);