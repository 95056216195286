import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';
import Login from '../Login';
import React from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	loggedIn: state.loggedIn
});


class DashboardLayout extends React.Component {
	static isSidebarOpen() {
		return document
			.querySelector('.cr-sidebar')
			.classList.contains('cr-sidebar--open');
	}

	componentWillReceiveProps({ breakpoint }) {
		if (breakpoint !== this.props.breakpoint) {
			this.checkBreakpoint(breakpoint);
		}
	}

	componentDidUpdate({ loggedIn, breakpoint }) {
		if (!loggedIn && this.props.loggedIn) {
			this.checkBreakpoint(breakpoint);
		}
	}

	componentDidMount() {
		this.checkBreakpoint(this.props.breakpoint);
	}

	// close sidebar when
	handleContentClick = () => {
		// close sidebar if sidebar is open and screen size is less than `md`
		if (
			DashboardLayout.isSidebarOpen() &&
			(this.props.breakpoint === 'xs' ||
				this.props.breakpoint === 'sm' ||
				this.props.breakpoint === 'md')
		) {
			this.openSidebar('close');
		}
	};

	checkBreakpoint(breakpoint) {
		switch (breakpoint) {
			case 'xs':
			case 'sm':
			case 'md':
				return this.openSidebar('close');

			case 'lg':
			case 'xl':
			default:
				return this.openSidebar('open');
		}
	}

	openSidebar(openOrClose) {
		if (!this.props.loggedIn) return;
		if (openOrClose === 'open') {
			return document
				.querySelector('.cr-sidebar')
				.classList.add('cr-sidebar--open');
		}
		document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
	}

	render() {
		const { children } = this.props;
		return (
			<React.Fragment>
				{this.props.loggedIn ?
					<main className="cr-app bg-light">
						<Sidebar />
						<Content fluid onClick={this.handleContentClick}>
							<Header />
							{children}
						</Content>

						<NotificationSystem
							dismissible={false}
							ref={notificationSystem =>
								(this.notificationSystem = notificationSystem)
							}
							style={NOTIFICATION_SYSTEM_STYLE}
						/>
					</main>
					:
					<Login />
				}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(DashboardLayout);
