import React from 'react';
import { Form, Text } from 'informed';
import { required } from './utilities/Validation';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { login } from '../redux/actionCreators';


const mapStateToProps = state => ({
	loginError: state.loginError ? true : false
});

const mapDispatchToProps = dispatch => ({
	login: user => dispatch(login(user))
});

const Login = (props) => {

	const login = (val) => {
		props.login(val);
	}

	return (
		<div className="container-fluid bg-primary" style={{ height: '100vh' }}>
			<div className="row d-flex align-items-center h-100">
				<div className="col align-items-center flex-column d-flex" >
					<div className="card bg-primary border-light p-5">
						<Form id="login" className="needs-validation" onSubmit={login}>
							{({ formState }) => (
								<React.Fragment>
									<div className="col flex-column align-items-center d-flex">
										<h1 className="text-light">Login</h1>
										<div className="form-group">
											<label className="font-weight-bold text-light" htmlFor="username">Username</label>
											<Text
												field="username"
												id="username"
												validate={required}
												validateOnBlur
												className={
													classNames({
														'form-control': true,
														'is-invalid': formState.errors.username
													})
												}
											/>
											<div className="invalid-feedback">{formState.errors.username}</div>
										</div>
										<div className="form-group">
											<label className="font-weight-bold text-light" htmlFor="username">Password</label>
											<Text
												type="password"
												field="password"
												id="password"
												validate={required}
												validateOnBlur
												className={
													classNames({
														'form-control': true,
														'is-invalid': formState.errors.password
													})
												}
											/>
											<div className="invalid-feedback">{formState.errors.password}</div>
										</div>
										<button type="submit" className="btn btn-outline-light m-4">Login</button>
									</div>
									<div className="small text-danger">{props.loginError && 'Your username or password is incorrect.  Please try again.'}</div>
								</React.Fragment>
							)}
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);