export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGGING_IN = "LOGGING_IN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const UPDATE_PLAYER_STATE = "UPDATE_PLAYER_STATE";

export const ADD_TRACKS = "ADD_TRACKS";
export const ADD_THEMES = "ADD_THEMES";
export const REMOVE_TRACK = "REMOVE_TRACK";
export const REMOVE_ALL_TRACK = "REMOVE_ALL_TRACK";
export const REMOVE_THEME = "REMOVE_THEME";
export const REMOVE_ALL_THEMES = "REMOVE_ALL_THEMES";
export const SELECT_ACTIVE_TRACK = "SELECT_ACTIVE_TRACK";
export const SELECT_ACTIVE_THEME = "SELECT_ACTIVE_THEME";
export const UPDATE_TRACK = "UPDATE_TRACK";
export const UPDATE_THEME = "UPDATE_THEME";

export const SET_GAME = "SET_GAME";
export const ADD_ROUND = "ADD_ROUND";
export const UPDATE_GAME_STATUS = "UPDATE_GAME_STATUS";