import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Button } from 'reactstrap';
import { required } from '../utilities/Validation';
import { alphaOnly, uppercase } from '../utilities/Mask';
import { Form } from 'informed';
import { WrappedText } from '../utilities/formElements';
import { subscribeToGame, emitGameStatus, unsubscribeToGame } from '../../redux/actionCreators';
import axios from 'axios';
import { error } from '../utilities/tools';
import posed, { PoseGroup } from 'react-pose';
import mongoose from 'mongoose';
import PartierMenu from './PartierMenu';
import Cookie from 'js-cookie';
import moment from 'moment';


const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

const mapDispatchToProps = dispatch => ({
	subscribeToGame: (code, cb) => dispatch(subscribeToGame(code, cb)),
	unsubscribeToGame: (code) => dispatch(unsubscribeToGame(code)),
	emitGameStatus: (code, update, type) => dispatch(emitGameStatus(code, update, type))
});

const Avatar = posed.div({
	enter: {
		opacity: 1,
		delay: ({ index }) => index * 300,
		transition: {
			duration: 750
		},
		x: 0
	},
	exit: {
		opacity: 0,
		x: ({ index }) => index % 2 === 1 ? 100 : -100
	}
});

const Taken = posed.div({
	enter: {
		position: 'relative',
		opacity: 1,
		width: 100,
		height: 100,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto',
		transition: {
			duration: 200,
			type: 'spring',
			stiffness: 2000,
			damping: 10,
		}
	},
	exit: {
		position: 'relative',
		opacity: 0,
		width: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto'
	}
});

class Partier extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			code: null,
			error: null,
			page: 'roomCode',
			avatarChoices: [],
			playerId: null
		};

		this.joinGame = this.joinGame.bind(this);
		this.register = this.register.bind(this);
		this.logout = this.logout.bind(this);

		this.isCancelled = false;
		this.name = null;
		this.email = null;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	componentDidMount() {
		const _id = Cookie.get('partierId');
		const code = Cookie.get('partierCode');
		const date = Cookie.get('partierDate');

		if (_id && code && date && moment(date).isSame(moment(), 'day')) {
			this.props.subscribeToGame(code, res => {
				if (!res) {
					Cookie.remove('partierCode', { path: '' });
					Cookie.remove('partierDate', { path: '' });
					Cookie.remove('partierId', { path: '' });
				} else {
					!this.isCancelled && this.setState({ error: null, page: 'game', playerId: _id })
				}
			});
		}

		axios.get('/exp/partier/avatars')
			.then(res => {
				!this.isCancelled && this.setState({ avatarChoices: res.data });
			})
			.catch(err => error(err));
	}

	componentDidUpdate(prevProps) {
		// manage bad game code
		if (prevProps.gameStatus && this.props.gameStatus === false) {
			!this.isCancelled && this.setState({ error: 'Sorry, that code is not valid.  Please try again.' });
		}

		// set gameCode and add player
		if (prevProps.gameStatus && !prevProps.gameStatus.code && this.props.gameStatus && this.props.gameStatus.code && this.state.playerId === null) {
			const playerId = mongoose.Types.ObjectId().toHexString();

			!this.isCancelled && this.setState({ code: this.props.gameStatus.code, playerId: playerId, page: 'register' });
			this.props.emitGameStatus(this.props.gameStatus.code, { name: this.name, _id: playerId, email: this.email }, 'addPlayer');
		}

		// logout when game ends
		if (prevProps.gameStatus && !prevProps.gameStatus.ended && this.props.gameStatus && this.props.gameStatus.ended) {
			this.logout();
		}
	}

	joinGame(val) {
		this.props.subscribeToGame(val.code, res => {
			if (!res) {
				!this.isCancelled && this.setState({ error: 'invalid room code' });
			} else {
				!this.isCancelled && this.setState({ error: null });
				this.name = val.name;
				this.email = val.email;
			}
		});
	}

	register(avatar) {
		this.props.emitGameStatus(this.state.code, { _id: this.state.playerId, avatar: avatar }, 'addPlayerAvatar');
		!this.isCancelled && this.setState({ page: 'game' });

		Cookie.set('partierId', this.state.playerId);
		Cookie.set('partierCode', this.state.code);
		Cookie.set('partierDate', moment().format('YYYY-MM-DD'));
	}

	logout() {
		Cookie.remove('partierCode', { path: '' });
		Cookie.remove('partierDate', { path: '' });
		Cookie.remove('partierId', { path: '' });

		this.props.unsubscribeToGame(this.state.code);

		!this.isCancelled && this.setState({
			code: null,
			error: null,
			page: 'roomCode',
			playerId: null
		});

	}


	render() {
		const usedAvatars = this.props.gameStatus.players ? this.props.gameStatus.players.filter(el => el.avatar).map(el => el.avatar) : [];
		const avatars = this.state.avatarChoices.map(el => ({
			avatar: el,
			taken: usedAvatars.includes(el)
		}));

		let player = {};
		if (this.props.gameStatus.players && this.props.gameStatus.players.find(el => el._id === this.state.playerId)) {
			player = this.props.gameStatus.players.find(el => el._id === this.state.playerId);
		}


		const pages = page => {
			switch (page) {
				case 'roomCode':
					return (
						<Row className="h-75">
							<Col className="d-flex flex-column align-items-center justify-content-center text-center">
								<h1 className="cabin">Hello, and welcome to the party!</h1>
								<Form id="code" onSubmit={this.joinGame}>
									{({ formState }) =>
										<React.Fragment>
											<FormGroup>
												<WrappedText
													id="code"
													placeholder="room code"
													field="code"
													validate={required}
													mask={val => alphaOnly(uppercase(val))}
												/>
												<small className="text-danger">{this.state.error}</small>
											</FormGroup>
											<FormGroup>
												<WrappedText
													id="name"
													placeholder="name"
													field="name"
													validate={required}
												/>
												<small className="text-danger">{formState.errors.name}</small>
											</FormGroup>
											<FormGroup>
												<WrappedText
													id="email"
													placeholder="email"
													field="email"
													validate={required}
												/>
												<small className="text-danger">{formState.errors.email}</small>
											</FormGroup>
											<Button type="submit" color="secondary">Join Game</Button>
										</React.Fragment>
									}
								</Form>
								<small className="p-4">Note: your email will be used to send you your personalized playlist made of songs you selected during the game.<br /><br />Extra note: your name will basically not be used at all.  That's the sort of thing that happens when you play a game that is in beta.</small>
							</Col>
						</Row>
					);
				case 'register':
					return (
						<Row style={{ maxWidth: 500 }}>
							<Col className="d-flex flex-column align-items-center pt-3 text-center">
								<h3 className="cabin">Select Your True Self, {player.name}</h3>
								<Row className="py-2">
									{avatars.map((choice) =>
										<Col xs="6" key={choice.avatar} className="d-flex justify-content-center pb-4">
											<div style={{ height: 100, width: 100 }}>
												{choice.taken ?
													<PoseGroup>
														<Taken key="taken" initialPose="exit">
															<img key={choice.avatar} src={require(`../../assets/img/avatars/${choice.avatar}.png`)} alt={choice.avatar} className="w-100 h-100" style={{ position: 'relative', top: 0, left: 0 }} />
															<img src={require('../../assets/img/avatars/ex.png')} alt='ex' className="w-100 h-100" style={{ position: 'absolute', top: 0, left: 0 }} />
														</Taken>
													</PoseGroup>
													:
													<Avatar pose="enter" initialPose="exit" index={avatars.filter(el => !el.taken).findIndex(el => el.avatar === choice.avatar)}>
														<img key={choice.avatar} src={require(`../../assets/img/avatars/${choice.avatar}.png`)} alt={choice.avatar} width={100} onClick={() => this.register(choice.avatar)} />
													</Avatar>
												}
											</div>
										</Col>
									)}
								</Row>
							</Col>
						</Row>
					);
				case 'game':
					return (
						<PartierMenu playerId={this.state.playerId} logout={this.logout} />
					)
				default:
					return (
						<div></div>
					);
			}
		};


		return (
			<Container fluid className="h-100 w-100 bg-primary text-light p-0 d-flex flex-column align-items-center">
				{pages(this.state.page)}
			</Container>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Partier);