import React from 'react';
import posed from 'react-pose';
import ReactTimeout from 'react-timeout';

const GuessedPose = posed.div({
	enter: {
		width: 750,
		height: 750,
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		borderRadius: '50%',
		color: '#000',
		opacity: 1,
		transition: {
			duration: 1000,
			type: 'spring',
			stiffness: 1000,
			damping: 10,
			delay: 500
		}
	},
	exit: {
		width: 0,
		height: 0,
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		borderRadius: '50%',
		color: '#000',
		opacity: 0,
		transition: {
			duration: 0,
			delay: 0
		}
	}
});

class ItemGuessed extends React.Component {

	componentDidMount() {
		this.props.setTimeout(() => {
			this.props.reset('guessed');
		}, 2000);
	}

	render() {
		return (
			<div className="h-100 w-100 bg-primary">
				<GuessedPose pose="enter" initialPose="exit" className="bg-gradient-theme-top text-light">
					<div style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						maxWidth: 725,
						transform: "translate(-50%, -50%)"
					}}>
						<h1 className="display-3 cabin">{this.props.type}</h1>
						<h1 className="display-1 cabin">{this.props.item}</h1>
					</div>
				</GuessedPose>
			</div>
		);
	}
}

export default ReactTimeout(ItemGuessed);