import React from 'react';
import {
    Col,
    ListGroupItemHeading,
    ListGroup,
    Row, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import {FiEdit, FiPlusCircle, FiXCircle} from 'react-icons/fi';
import axios from 'axios';
import {DropTarget} from 'react-dnd';
import {DnDTypes} from '../utilities/constants';
import {Form} from "informed";
import {WrappedText} from "../utilities/formElements";
import {required} from "../utilities/Validation";


const dropTarget = {
    drop(props, monitor) {
        const item = monitor.getItem();

        switch (monitor.getItemType()) {
            case 'theme_search_result':
                props.selectActiveTheme(item);
                break;
            case 'track_search_result':
                if (!props.activeTheme.eligibleTracks.map(el => el._id).includes(item._id)) {
                    props.updateTheme({
                        ...props.activeTheme,
                        eligibleTracks: [...props.activeTheme.eligibleTracks.map(el => el._id), item._id]
                    });
                }
                break;
            default:
                break;

        }
    }
};

const collect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
    }
};

class ThemeDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editHintModal: {
                open: false,
                hint: '',
                index: undefined
            },
            addHintModal: false
        }

        this.isCancelled = false;

        this.editHint = this.editHint.bind(this);
        this.updateHint = this.updateHint.bind(this);
        this.deleteHint = this.deleteHint.bind(this);
        this.addHint = this.addHint.bind(this);
    }


    componentWillUnmount() {
        this.isCancelled = true;
    }

    editHint(index) {
        !this.isCancelled && this.setState({
            editHintModal: {
                open: true,
                hint: this.props.activeTheme.themeHints[index],
                index
            }
        });
    }

    updateHint(val) {
        const themeUpdate = {_id: this.props.activeTheme._id, themeHints: [...this.props.activeTheme.themeHints]};

        themeUpdate.themeHints[this.state.editHintModal.index] = val.hint;

        axios.post('/theme/update', themeUpdate)
            .then(res => {
                this.props.updateTheme(res.data);
                if (this.props.activeTheme._id === res.data._id) {
                    this.props.selectActiveTheme(res.data);
                }

                !this.isCancelled && this.setState({editHintModal: {open: false, hint: ''}});
            })
            .catch(err => window.console && console.log(err));
    }

    deleteHint() {
        if (window.confirm('Are you sure you wnat to delete this hint?')) {
            const themeUpdate = {_id: this.props.activeTheme._id, themeHints: [...this.props.activeTheme.themeHints]};

            themeUpdate.themeHints.splice(this.state.editHintModal.index, 1);

            axios.post('/theme/update', themeUpdate)
                .then(res => {
                    this.props.updateTheme(res.data);
                    if (this.props.activeTheme._id === res.data._id) {
                        this.props.selectActiveTheme(res.data);
                    }

                    !this.isCancelled && this.setState({editHintModal: {open: false, hint: ''}});
                })
                .catch(err => window.console && console.log(err));
        }
    }

    addHint(val) {
        const themeUpdate = {_id: this.props.activeTheme._id, themeHints: [...this.props.activeTheme.themeHints]};

        themeUpdate.themeHints.push(val.hint);

        axios.post('/theme/update', themeUpdate)
            .then(res => {
                this.props.updateTheme(res.data);
                if (this.props.activeTheme._id === res.data._id) {
                    this.props.selectActiveTheme(res.data);
                }

                !this.isCancelled && this.setState({editHintModal: {open: false, hint: ''}});
            })
            .catch(err => window.console && (err));
    }

    render() {
        return (this.props.connectDropTarget(
            <div>
                <ListGroupItemHeading>Eligible Tracks</ListGroupItemHeading>
                <ListGroup>
                    {this.props.activeTheme.eligibleTracks.map(track =>
                        <button
                            type="button"
                            key={track._id}
                            className="list-group-item list-group-item-action"
                            onClick={() => {
                                this.props.addTracks([track]);

                                axios.get('/theme/of-track/' + track._id)
                                    .then(res => {
                                        !this.isCancelled && this.props.selectActiveTrack({...track, themes: res.data});
                                    })
                                    .catch(err => window.console && console.log(err));
                            }}
                        >
                            <Row>
                                <Col xs="auto">
                                    <img src={track.albumCover} alt="album cover" height={50}/>
                                </Col>
                                <Col>
                                    {track.name}<br/>
                                    <em>{track.artist.map(el => el.name).join(', ')}</em>
                                </Col>
                                <Col xs="auto">
                                    <FiXCircle className="text-danger" onClick={() => {
                                        const newTracks = this.props.activeTheme.eligibleTracks.filter(el => el._id !== track._id);
                                        this.props.updateTheme({...this.props.activeTheme, eligibleTracks: newTracks});
                                    }}/>
                                </Col>
                            </Row>
                        </button>
                    )}
                </ListGroup>
                <ListGroupItemHeading className="pt-3">Theme Hints</ListGroupItemHeading>
                <ListGroup>
                    {this.props.activeTheme.themeHints.map((hint, index) =>
                        <ListGroupItem key={index} action style={{cursor: 'pointer'}}
                                       onClick={() => this.editHint(index)}>
                            <Row>
                                <Col>
                                    {hint}
                                </Col>
                                <Col xs="auto">
                                    <FiEdit/>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    )}

                </ListGroup>
                <div className="py-2" />
                <Button type="button" onClick={() => !this.isCancelled && this.setState({addHintModal: true})}>
                    Add a new hint <FiPlusCircle />
                </Button>
                <Modal isOpen={this.state.editHintModal.open}
                       toggle={() => !this.isCancelled && this.setState({editHintModal: {open: false, hint: ''}})}>
                    <ModalHeader
                        toggle={() => !this.isCancelled && this.setState({editHintModal: {open: false, hint: ''}})}>
                        Edit Hint
                    </ModalHeader>
                    <Form onSubmit={this.updateHint}>
                        {({formState}) =>
                            <>
                                <ModalBody>
                                    <WrappedText
                                        field="hint"
                                        validate={required}
                                        initialValue={this.state.editHintModal.hint}
                                    />
                                    <div className='invalid-feedback'>{formState.errors.hint}</div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" color="primary">Update</Button>
                                    <Button type="button" color="secondary" onClick={this.deleteHint}>Delete</Button>
                                    <Button type="button" color="dark"
                                            onClick={() => !this.isCancelled && this.setState({
                                                editHintModal: {
                                                    open: false,
                                                    hint: ''
                                                }
                                            })}>Cancel</Button>
                                </ModalFooter>
                            </>
                        }
                    </Form>

                </Modal>
                <Modal isOpen={this.state.addHintModal}
                       toggle={() => !this.isCancelled && this.setState({addHintModal: false})}>
                    <ModalHeader
                        toggle={() => !this.isCancelled && this.setState({addHintModal: false})}>
                        Add Hint
                    </ModalHeader>
                    <Form onSubmit={this.addHint}>
                        {({formState}) =>
                            <>
                                <ModalBody>
                                    <WrappedText
                                        field="hint"
                                        validate={required}
                                    />
                                    <div className='invalid-feedback'>{formState.errors.hint}</div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" color="primary">Add</Button>
                                    <Button type="button" color="secondary"
                                            onClick={() => !this.isCancelled && this.setState({addHintModal: false})}>Cancel</Button>
                                </ModalFooter>
                            </>
                        }
                    </Form>

                </Modal>
            </div>
        ));
    }
}

export default DropTarget([DnDTypes.TRACK_SEARCH_RESULT, DnDTypes.THEME_SEARCH_RESULT], dropTarget, collect)(ThemeDetail);
