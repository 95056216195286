import React from 'react';
import { connect } from 'react-redux';
import { fetchAuthentication } from '../redux/actionCreators';


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	fetchAuthentication: (cb) => dispatch(fetchAuthentication(cb))
});

class StateController extends React.Component {
	constructor(props) {
		super(props);

		this.isCancelled = false;
	}

	componentDidMount() {
		this.props.fetchAuthentication();
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	render() {
		return this.props.children;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StateController);
