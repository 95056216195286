import React from 'react';
import { MdSearch } from 'react-icons/md';
import {
	Input,
	Form,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Table,
} from 'reactstrap';
import axios from 'axios';
import { css } from '@emotion/core';
import { PacmanLoader } from 'react-spinners';
import colors from '../../styles/color';
import { addThemes } from '../../redux/actionCreators';
import { connect } from 'react-redux';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const mapDispatchToProps = dispatch => ({
	addThemes: (themes) => dispatch(addThemes(themes))
});

class SearchTheme extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			value: '',
			modal: false,
			results: [],
			loading: false
		}

		this.onSubmit = this.onSubmit.bind(this);
		this.toggle = this.toggle.bind(this);
		this.addTheme = this.addTheme.bind(this);
		this.isCancelled = false;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	onSubmit = (e) => {
		e.preventDefault();
		if (this.state.value === '') return;

		axios.get('/theme?search=' + this.state.value)
			.then(res => {
				!this.isCancelled && this.setState({ loading: false, results: res.data });
			})
			.catch(err => window.console && console.error(err));
		!this.isCancelled && this.setState({ modal: true, loading: true });

	}

	toggle = () => {
		!this.isCancelled && this.setState(prevState => ({
			...prevState,
			results: [],
			modal: !prevState.modal,
			value: prevState.modal ? '' : prevState.value
		}));
	}

	addTheme(theme) {
		this.props.addThemes(theme);
		this.toggle();
	}

	render() {
		return (
			<React.Fragment>
				<Form className="cr-search-form" onSubmit={this.onSubmit}>
					<MdSearch
						size="20"
						className="cr-search-form__icon-search text-secondary mt-2"
						onClick={this.onSubmit}
						style={{ cursor: 'pointer' }}
					/>
					<Input
						type="search"
						className="cr-search-form__input"
						placeholder="Search themes..."
						value={this.state.value}
						onChange={(e) => this.setState({ value: e.target.value })}
					/>
				</Form>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Search Results</ModalHeader>
					<ModalBody>
						{this.state.loading ?
							<div className="m-2" style={{ height: 40 }}>
								<PacmanLoader
									color={colors.secondary}
									css={override}
									size={20}
									sizeUnit={'px'}
								/>
							</div>
							:
							<React.Fragment>
								<Button block className="bg-gradient-theme-left border-0" onClick={() => this.addTheme(this.state.results)}>Add All Themes To Workspace</Button>
								<Table hover>
									<tbody>
										{this.state.results.map(item =>
											<tr
												key={item._id}
												style={{
													cursor: 'pointer'
												}}
												onClick={() => {this.addTheme([item]); this.props.focusActiveTheme(item); }}
											>
												<td>
													<div className="lead">{item.theme} ({item.eligibleTracks.length} tracks)</div>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</React.Fragment>
						}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" type="button" onClick={this.toggle}>Close</Button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(SearchTheme);
