import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container } from 'reactstrap';
import posed, { PoseGroup } from 'react-pose';
import ReactTimeout from 'react-timeout';
import SplitText from 'react-pose-text';
import { FaQuestion, FaExclamation } from 'react-icons/fa';
import classnames from 'classnames';

const Hints = posed.div({
	enter: {
		opacity: 1,
		delay: 200,
		transition: {
			duration: 200
		}
	},
	exit: { opacity: 0 },
	flip: {
		transition: {
			velocity: 20,
		}
	}
});

const charPoses = {
	enter: {
		opacity: 1,
		delay: ({ charIndex }) => charIndex * 30 + 300
	},
	exit: {
		opacity: 0
	}
};

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

class HintDisplay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showMainHint: false
		};

		this.isCancelled = false;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}


	render() {

		const hints = this.props.gameStatus.round.themeGuessed ? [{
			type: 'theme',
			hint: this.props.gameStatus.round.theme,
			_id: 'uniqueKey'
		}] : this.props.gameStatus.round.hints.filter(el => el.type === 'theme');

		return (
			<Container fluid className="d-flex align-items-center flex-column bg-gradient-theme-top px-0">
				<PoseGroup className=" w-100 text-center">
					{hints.reverse().map((el, index) =>
						<Hints key={el._id}>
							<Row>
								{index > 0 &&
									<Col xs="2" className="bg-primary"></Col>
								}
								<Col xs="auto" className="d-flex align-items-center m-3">
									<h1
										className={classnames({
											'display-4': index > 0,
											'display-2': index === 0
										})}
									>
										{this.props.gameStatus.round.themeGuessed ?
											<FaExclamation />
											:
											<FaQuestion />
										}
									</h1>
								</Col>
								<Col className=" d-flex align-items-center justify-content-center" >
									<h1
										className={classnames({
											neucha: true,
											'h1': index > 0,
											'display-4': index === 0
										})}
									>
										{el.hint === 'ALBUM' ?
											<span><img alt="album cover" className="py-2" src={this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].albumCover} width={index === 0 ? 250 : 150} />&nbsp;
											</span>
											:
											<SplitText charPoses={charPoses}>
												{el.hint}
											</SplitText>
										}
									</h1>
								</Col>
								<Col xs="auto" className="d-flex align-items-center m-3">
									<h1
										className={classnames({
											'display-4': index > 0,
											'display-2': index === 0
										})}
									>
										{this.props.gameStatus.round.themeGuessed ?
											<FaExclamation />
											:
											<FaQuestion />
										}
									</h1>
								</Col>
								{index > 0 &&
									<Col xs="2" className="bg-primary"></Col>
								}
							</Row>
							<div className="bg-primary py-2 border-0"></div>
						</Hints>
					)}
				</PoseGroup>
			</Container>
		);
	}
}

export default connect(mapStateToProps)(ReactTimeout(HintDisplay));
