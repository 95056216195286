import React from 'react';
import {
	Container,
	Row,
	Col
} from 'reactstrap';
import { IconWidget } from '../../components/Widget';
import { MdList, MdQueueMusic } from 'react-icons/md';
import axios from 'axios';


class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			trackCount: null,
			themeCount: null
		}

		this.isCancelled = false;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	componentDidMount() {
		axios.get('/stats/tracks')
			.then(res => {
				!this.isCancelled && this.setState({
					trackCount: res.data.toString()
				});
			})
			.catch(err => window.console && console.error(err));

		axios.get('/stats/themes')
			.then(res => {
				!this.isCancelled && this.setState({
					themeCount: res.data.toString()
				});
			})
			.catch(err => window.console && console.error(err));
	}


	render() {
		return (
			<Container fluid className="h-100 p-4">
				<Row>
					<Col lg={4} md={6} sm={6} xs={12} className="mb-3">
						<IconWidget
							bgColor='primary'
							icon={MdList}
							title='Themes Created'
							subtitle={this.state.themeCount}
						/>
					</Col>
					<Col lg={4} md={6} sm={6} xs={12} className="mb-3">
						<IconWidget
							bgColor='secondary'
							icon={MdQueueMusic}
							title='Tracks Imported'
							subtitle={this.state.trackCount}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Dashboard;