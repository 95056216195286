import React from 'react';
import {
	Container,
	Row,
	Col,
	Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo/tmglogo.png';


class Homepage extends React.Component {

	render() {
		return (
			<div className='bg-gradient-theme h-100' >
				<Container className='text-light d-flex align-items-center justify-content-center flex-column h-100' >
					<Row className="d-flex align-items-center pt-5">
						<Col className="align-items-center flex-column d-flex">
							<Row>
								<Col>
									<img src={logo} alt="logo" className="w-100" />
								</Col>
							</Row>
							<Row className="w-100  pt-5 pb-3">
								<Col md="6" className="text-center">
									<Link to='/display'><Button color="primary" size="lg" className="m-2 cabin" >TV Display</Button></Link>
								</Col>
								<Col md="6" className="text-center">
									<Link to='/partier'><Button color="primary" size="lg" className="m-2 cabin" >Partier</Button></Link>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className='w-100 flex-grow-1'>
						<Col md={{ size: 6, offset: 3 }} className="d-flex align-items-md-end text-center justify-content-center p-2">
							<span className="lead cabin" >brought to you by C + C Game Factory</span>
						</Col>
						<Col md={{ size: 3 }} className="d-flex align-items-end justify-content-center p-2">
							<Link to="/dashboard"><Button color="light" outline>Dashboard</Button></Link>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Homepage;