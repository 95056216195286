import React from 'react';
import posed from 'react-pose';
import { Link } from 'react-router-dom';


const PoseContainer = posed.div({
	begin: {
		position: 'absolute',
		top: '100vh',
		bottom: '100vh',
		left: 0,
		right: 0,

	},
	end: {
		position: 'absolute',
		top: '-100vh',
		bottom: '0px',
		left: 0,
		right: 0,
		transition: {
			duration: 15000
		}
	}
});

class Credits extends React.Component {

	render() {

		return (
			<div className="h-100 w-100 bg-primary">
				<PoseContainer pose='end' initialPose='begin'>
					<div className="container-fluid text-light text-center d-flex flex-row" style={{ overflow: 'hidden', height: '100vh', width: '100vw' }}>
						<div className="col-md-3 h-100">
							<div className="row h-50 d-flex align-items-center justify-content-center">
								<img src={require('../../assets/img/gifs/djloop.gif')} alt="logo" width={'50%'} />
							</div>
							<div className="row h-50 d-flex align-items-center justify-content-center">
								<img src={require('../../assets/img/gifs/dancer.gif')} alt="logo" width={'50%'} />
							</div>
						</div>
						<div className="col-md-6 h-100">
							<div className="row h-25 justify-content-center w-100">
								<h1 className="display-1 cabin">
									The Music Game!
							</h1>
							</div>

							<div className="row h-75 justify-content-center w-100 d-flex flex-column">
								<p className="display-4 neucha">
									concept and game building by Christian
						</p>
								<br /><br />
								<p className="display-4 neucha">
									animations and graphic design by Casey
						</p>
								<br /><br />
								<p className="display-4 neucha">
									fun times by YOU!
						</p>
							</div>
						</div>

						<div className="col-md-3 h-100 d-flex align-items-center justify-content-center">
							<img src={require('../../assets/img/gifs/wine.gif')} alt="logo" width={'50%'} />
						</div>
					</div>
					<div style={{ height: '100vh' }} className="container-fluid">
						<div className='row h-100 align-items-center justify-content-center d-flex flex-column text-center'>
							<Link to="/"><img src={require('../../assets/img/logo/tmglogo.png')} alt="logo" width={'50%'} /></Link>
							<span className='lead text-light cabin'>brought to you by C + C Game Factory</span>
						</div>
					</div>
				</PoseContainer>
			</div>
		);
	}
}

export default Credits;