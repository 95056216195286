import React from 'react';
import posed from 'react-pose';
import ReactTimeout from 'react-timeout';
import SplitText from 'react-pose-text';
import classnames from 'classnames';
import dancer from '../../../assets/img/gifs/dancer.gif';

const PosedDiv = posed.div({
	exit: {
		opacity: 0
	},
	enter: {
		width: 1500,
		height: 300,
		position: 'absolute',
		textAlign: 'center',
		transition: {
			duration: 300,
		}
	},
	initial: {
		width: 300,
		height: 50,
		position: 'absolute',
		top: 300,
		bottom: 0,
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		transition: {
			duration: 300,
		}
	}
});

const textPoses = {
	initial: {
		opacity: 0,
		y: 100,
		fontSize: '1em',
	},
	enter: {
		opacity: 1,
		y: 0,
		delay: ({ charIndex }) => charIndex * 75,
		fontSize: '3em',
	},
	exit: {
		opacity: 0
	}
};
class PartyMode extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			status: 'enter'
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.partyMode && !this.props.partyMode) {
			this.props.reset('partyMode');
		}
	}


	render() {
		return (
			<div className={classnames({
				'w-100': true,
				'h-100': true,
				'd-none': this.state.status === 'gone',
				'bg-primary': true
			})}>
				<PosedDiv pose={this.state.status} initialPose="initial">
					<h1 className="display-4 cabin">
						<SplitText initialPose="initial" pose={this.state.status} charPoses={textPoses}>
							Party Mode
						</SplitText>
					</h1>
					<img alt="dancer" src={dancer} width={200} />
				</PosedDiv>
			</div>
		);
	}
}

export default ReactTimeout(PartyMode);