import React from 'react';
import { TiMediaPlay, TiMediaPause, TiMediaRewind, TiMediaFastForward } from 'react-icons/ti';
import axios from 'axios';
import PlayerSlider from './PlayerSlider';
import { connect } from 'react-redux';
import { subscribeToPlayer, unsubscribeToPlayer } from '../../redux/actionCreators';

/* takes optional props
*	previousTrack: function wiht no arguments
*	nextTrack: function with no arguments
*/	


const mapStateToProps = state => ({
	playerSubscription: state.playerState
});

const mapDispatchToProps = dispatch => ({
	subscribeToPlayer: () => dispatch(subscribeToPlayer()),
	unsubscribeToPlayer: () => dispatch(unsubscribeToPlayer())
});

class Player extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			progress: this.props.playerSubscription.progress_ms
		}

		this.progressTimer = null;
		this.isCancelled = false;
		this.playPause = this.playPause.bind(this);
		this.seek = this.seek.bind(this);
		this.estimatedProgress = this.estimatedProgress.bind(this);
	}

	componentDidMount() {
		this.estimatedProgress();
		this.props.subscribeToPlayer();
	}

	componentWillUnmount() {
		this.isCancelled = true;
		clearInterval(this.progressTimer);
		this.props.unsubscribeToPlayer();
	}

	componentWillReceiveProps(props) {
		if (this.state.progress !== props.playerSubscription.progress_ms) {
			!this.isCancelled && this.setState({ progress: props.playerSubscription.progress_ms });
		}
	}

	playPause() {
		axios.get('/spotify/play-pause')
			.then(res => null)
			.catch(err => window.console && console.log(err));
	}

	seek(val) {
		if (!this.props.playerSubscription.item) return;

		const newVal = Math.round(val * this.props.playerSubscription.item.duration_ms);
		axios.get('/spotify/seek/' + newVal.toString())
			.then(res => null)
			.catch(err => window.console && console.log(err));
	}

	estimatedProgress() {
		this.progressTimer = setInterval(() => {
			!this.isCancelled && this.props.playerSubscription.is_playing && this.setState(prevState => ({ ...prevState, progress: prevState.progress + 500 }));
		}, 500);
	}

	render() {
		return (
			<div className='h-100 w-100 d-flex align-items-center justify-content-center'>
				<div className="container-fluid">
					{this.props.playerSubscription.device ?
						<React.Fragment>
							<div className="row">
								<div className="col d-flex flex-column align-items-center ">
									<div className="lead">{this.props.playerSubscription.item && this.props.playerSubscription.item.name}</div>
									<div className="lead"><em>{this.props.playerSubscription.item && this.props.playerSubscription.item.artists.map(el => el.name).join(', ')}</em></div>
								</div>
							</div>
							<div className="row">
								<PlayerSlider
									progress={this.state.progress}
									duration={this.props.playerSubscription.item && this.props.playerSubscription.item.duration_ms}
									onChange={(val) => this.seek(val)}
								/>
							</div>
							<div className="row " >
								<div className="col-4 d-flex flex-column align-items-center">
									{this.props.previousTrack && <button type="button" className="btn btn-link text-light" onClick={this.props.previousTrack}  ><TiMediaRewind className="" style={{ fontSize: '3em' }} /></button>}
								</div>
								<div className="col-4 d-flex flex-column align-items-center">
									<button type="button" className="btn btn-link text-light" onClick={this.playPause} >{this.props.playerSubscription.is_playing ? <TiMediaPause className="" style={{ fontSize: '3em' }} /> : <TiMediaPlay className="" style={{ fontSize: '3em' }} />} </button>
								</div>
								<div className="col-4 d-flex flex-column align-items-center">
									{this.props.nextTrack && <button type="button" className="btn btn-link text-light" onClick={this.props.nextTrack} ><TiMediaFastForward className="" style={{ fontSize: '3em' }} /></button>}
								</div>
							</div>
							<div className="row">
								<div className="col text-center lead">
									Current Timestamp: {Math.round(this.state.progress / 1000).toString()}s
								</div>
							</div>
						</React.Fragment>
						:
						<div className="row">
							<div className="col d-flex align-items-center justify-content-center lead">
								Start a Spotify player and initilize it by playing a song for a moment.
							</div>
						</div>
					}

				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);