import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import AlertPage from './pages/AlertPage';
import AuthModalPage from './pages/AuthModalPage';
import AuthPage from './pages/AuthPage';
import BadgePage from './pages/BadgePage';
import ButtonGroupPage from './pages/ButtonGroupPage';
import ButtonPage from './pages/ButtonPage';
import CardPage from './pages/CardPage';
import ChartPage from './pages/ChartPage';
import DashboardPage from './pages/DashboardPage';
import DropdownPage from './pages/DropdownPage';
import FormPage from './pages/FormPage';
import InputGroupPage from './pages/InputGroupPage';
import ModalPage from './pages/ModalPage';
import ProgressPage from './pages/ProgressPage';
import TablePage from './pages/TablePage';
import TypographyPage from './pages/TypographyPage';
import WidgetPage from './pages/WidgetPage';
import Homepage from './TMG/Homepage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import StateController from './TMG/StateController';
import Builder from './TMG/Builder/index';
import DJ from './TMG/DJ/index';
import Partier from './TMG/Partier/index';
import Display from './TMG/Display/index';
import DashboardLayout from './TMG/Layout/DashboardLayout';
import Dashboard from './TMG/Dashboard';
import './styles/styles.css';
import './styles/custom.scss';
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'

const getBasename = () => {
	return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};


class App extends React.Component {

	render() {
		return (
			<Provider store={store}>
				<StateController>
					<BrowserRouter basename={getBasename()}>
						<GAListener>
							<Switch>
								<LayoutRoute
									exact
									path="/"
									layout={EmptyLayout}
									component={props => (
										<Homepage {...props} />
									)}
								/>
								<LayoutRoute
									exact
									path="/partier"
									layout={EmptyLayout}
									component={props => (
										<Partier {...props} />
									)}
								/>
								<LayoutRoute
									exact
									path="/display"
									layout={EmptyLayout}
									component={props => (
										<Display {...props} />
									)}
								/>
								<LayoutRoute
									exact
									path="/dashboard"
									layout={(props) => <DashboardLayout {...this.props} {...props} />}
									component={props => (
										<Dashboard {...props} />
									)}
								/>
								<LayoutRoute
									exact
									path="/builder"
									layout={(props) => <DashboardLayout {...this.props} {...props} />}
									component={props => (
										<Builder {...props} />
									)}
								/>
								<LayoutRoute
									exact
									path="/dj"
									layout={(props) => <DashboardLayout {...this.props} {...props} />}
									component={props => (
										<DJ {...props} />
									)}
								/>
								<LayoutRoute
									exact
									path="/template/login"
									layout={EmptyLayout}
									component={props => (
										<AuthPage {...props} authState={STATE_LOGIN} />
									)}
								/>
								<LayoutRoute
									exact
									path="/template/signup"
									layout={EmptyLayout}
									component={props => (
										<AuthPage {...props} authState={STATE_SIGNUP} />
									)}
								/>
								<LayoutRoute
									exact
									path="/template/login-modal"
									layout={MainLayout}
									component={AuthModalPage}
								/>
								<LayoutRoute
									exact
									path="/template/"
									layout={MainLayout}
									component={DashboardPage}
								/>
								<LayoutRoute
									exact
									path="/template/buttons"
									layout={MainLayout}
									component={ButtonPage}
								/>
								<LayoutRoute
									exact
									path="/template/cards"
									layout={MainLayout}
									component={CardPage}
								/>
								<LayoutRoute
									exact
									path="/template/widgets"
									layout={MainLayout}
									component={WidgetPage}
								/>
								<LayoutRoute
									exact
									path="/template/typography"
									layout={MainLayout}
									component={TypographyPage}
								/>
								<LayoutRoute
									exact
									path="/template/alerts"
									layout={MainLayout}
									component={AlertPage}
								/>
								<LayoutRoute
									exact
									path="/template/tables"
									layout={MainLayout}
									component={TablePage}
								/>
								<LayoutRoute
									exact
									path="/template/badges"
									layout={MainLayout}
									component={BadgePage}
								/>
								<LayoutRoute
									exact
									path="/template/button-groups"
									layout={MainLayout}
									component={ButtonGroupPage}
								/>
								<LayoutRoute
									exact
									path="/template/dropdowns"
									layout={MainLayout}
									component={DropdownPage}
								/>
								<LayoutRoute
									exact
									path="/template/progress"
									layout={MainLayout}
									component={ProgressPage}
								/>
								<LayoutRoute
									exact
									path="/template/modals"
									layout={MainLayout}
									component={ModalPage}
								/>
								<LayoutRoute
									exact
									path="/template/forms"
									layout={MainLayout}
									component={FormPage}
								/>
								<LayoutRoute
									exact
									path="/template/input-groups"
									layout={MainLayout}
									component={InputGroupPage}
								/>
								<LayoutRoute
									exact
									path="/template/charts"
									layout={MainLayout}
									component={ChartPage}
								/>
								<LayoutRoute
									exact
									path="/template/register"
									layout={MainLayout}
									component={AuthPage}
								/>
								<Redirect to="/" />
							</Switch>
						</GAListener>
					</BrowserRouter>
				</StateController>
			</Provider>
		);
	}
}

const query = ({ width }) => {
	if (width < 575) {
		return { breakpoint: 'xs' };
	}

	if (576 < width && width < 767) {
		return { breakpoint: 'sm' };
	}

	if (768 < width && width < 991) {
		return { breakpoint: 'md' };
	}

	if (992 < width && width < 1199) {
		return { breakpoint: 'lg' };
	}

	if (width > 1200) {
		return { breakpoint: 'xl' };
	}

	return { breakpoint: 'xs' };
};

export default componentQueries(query)(DragDropContext(HTML5Backend)(App));
