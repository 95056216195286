import React from 'react';
import Scoreboard from './Scoreboard';
import TrackDisplay from './TrackDisplay';
import { connect } from 'react-redux';
import posed, { PoseGroup } from 'react-pose';
import RoundAnimation from './animations/RoundAnimation';
import NewLead from './animations/NewLead';
import ReactTimeout from 'react-timeout';
import SplitText from 'react-pose-text';
import ThemeHints from './ThemeHints';
import ArtistHints from './ArtistHints';
import TrackHints from './TrackHints';
import ItemGuessed from './animations/ItemGuessed';
import PartyMode from './animations/PartyMode';
import PeanutGallery from './PeanutGallery';
import Credits from './Credits';
import FinalScore from './animations/FinalScore';

const MainAnimation = posed.div({
	exit: {
		opacity: 0,
		position: 'absolute',
		width: '100%',
		height: '100%',
		transition: {
			duration: 300
		}
	},
	enter: {
		opacity: 1,
		position: 'absolute',
		width: '100%',
		height: '100%',
		transition: {
			duration: 300
		},
		delay: 300
	}
});

const TrackPose = posed.div({
	exit: {
		opacity: 0,
		y: 30,
		transition: {
			duration: 0
		}
	},
	enter: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 400
		}
	}
});

const charPoses = {
	exit: {
		opacity: 0
	},
	enter: {
		opacity: 1,
		delay: ({ charIndex }) => charIndex * 75
	}
};

const mapStateToProps = state => ({
	gameStatus: state.gameStatus
});

class MainDisplay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: 'round',
			leader: null,
			trackDisplay: true,
			guessedType: null,
			guessedItem: null
		}

		this.resetMainAnimation = this.resetMainAnimation.bind(this);
		this.isCancelled = false;
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	componentDidUpdate(prevProps) {
		// run partyMode animation
		if (!prevProps.gameStatus.partyMode && this.props.gameStatus.partyMode) {
			!this.isCancelled && this.setState({ page: 'partyMode' });
		}

		// run credits
		if (prevProps.gameStatus && !prevProps.gameStatus.ended && this.props.gameStatus && this.props.gameStatus.ended) {
			!this.isCancelled && this.setState({ page: 'finalScore' });
		}

		// run round animation
		if (prevProps.gameStatus.round.number !== this.props.gameStatus.round.number) {
			!this.isCanceled && this.setState({ page: 'round' });
		}

		// run track animation (small)
		if (prevProps.gameStatus.round.trackIndex !== this.props.gameStatus.round.trackIndex) {
			!this.isCancelled && this.setState({ trackDisplay: false }, () => {
				this.props.setTimeout(() => {
					!this.isCancelled && this.setState({ trackDisplay: true });
				}, 500);
			});
		}

		// run new lead animation
		if (prevProps.gameStatus.teams && prevProps.gameStatus.teams.length > 1) {
			const sortedOld = prevProps.gameStatus.teams.sort((a, b) => a.score > b.score ? -1 : 1);
			const sortedNew = this.props.gameStatus.teams.sort((a, b) => a.score > b.score ? -1 : 1);

			let oldLeader;
			let newLeader;

			if (sortedOld[0].score === sortedOld[1].score) {
				oldLeader = null;
			} else {
				oldLeader = sortedOld[0].name;
			}

			if (sortedNew[0].score === sortedNew[1].score) {
				newLeader = null;
			} else {
				newLeader = sortedNew[0].name;
			}

			if (newLeader && oldLeader !== newLeader) {
				!this.isCancelled && this.setState({ page: 'leader', leader: newLeader });
			}
		}

		// run item guessed animation for theme guessed
		if (prevProps.gameStatus.round.themeGuessed === false && this.props.gameStatus.round.themeGuessed === true) {
			!this.isCancelled && this.setState({ page: 'guessed', guessedType: 'theme', guessedItem: this.props.gameStatus.round.theme });
		}

		// run item guessed animation for track guessed
		if (prevProps.gameStatus.round.tracks[prevProps.gameStatus.round.trackIndex].trackGuessed === false && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].trackGuessed === true) {
			!this.isCancelled && this.setState({ page: 'guessed', guessedType: 'track', guessedItem: this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].name });

		}

		// run item guessed animation for artist guessed
		if (prevProps.gameStatus.round.tracks[prevProps.gameStatus.round.trackIndex].artistGuessed === false && this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artistGuessed === true) {
			!this.isCancelled && this.setState({ page: 'guessed', guessedType: 'artist', guessedItem: this.props.gameStatus.round.tracks[this.props.gameStatus.round.trackIndex].artist });
		}
	}

	resetMainAnimation(source) {
		this.state.page === source && !this.isCancelled && this.setState({ page: 'none' });
	}


	render() {

		const populateAnimation = page => {
			switch (page) {
				case 'round':
					return (
						<MainAnimation key={page}>
							<RoundAnimation
								number={this.props.gameStatus.round.number}
								reset={this.resetMainAnimation}
							/>
						</MainAnimation>
					);
				case 'leader':
					return (
						<MainAnimation key={page}>
							<NewLead
								team={this.state.leader}
								reset={this.resetMainAnimation}
							/>
						</MainAnimation>
					);
				case 'guessed':
					return (
						<MainAnimation key={page}>
							<ItemGuessed
								type={this.state.guessedType}
								item={this.state.guessedItem}
								reset={this.resetMainAnimation}
							/>
						</MainAnimation>
					);
				case 'partyMode':
					return (
						<MainAnimation key={page}>
							<PartyMode
								partyMode={this.props.gameStatus.partyMode}
								reset={this.resetMainAnimation}
							/>
						</MainAnimation>
					);
				case 'finalScore':
					return (
						<MainAnimation key={page}>
							<FinalScore teams={this.props.gameStatus.teams.map(el => Object.assign({}, el)).sort((a,b) => a.score < b.score ? -1 : 1)} credits={() => !this.isCancelled && this.setState({ page: 'credits' })}/>
						</MainAnimation>
					);
				case 'credits':
					return (
						<MainAnimation key={page}>
							<Credits />
						</MainAnimation>
					);
				default:
					return (
						<MainAnimation key={page} className="d-none">
						</MainAnimation>
					);
			}
		}

		return (
			<React.Fragment>
				<div style={{ position: 'absolute', top: 10, bottom: 0, left: 0, right: 0, textAlign: 'center', width: 300, height: 50, marginLeft: 'auto', marginRight: 'auto' }}>
					<h1 className="display-4 cabin">Round {this.props.gameStatus.round && this.props.gameStatus.round.number}</h1>
					<PoseGroup>
						{this.state.trackDisplay &&
							<TrackPose key='track'>
								<h1 className=" cabin">
									<SplitText pose={this.state.trackDisplay ? 'enter' : 'exit'} charPoses={charPoses}>
										{`Track ${this.props.gameStatus.round.trackIndex + 1}`}
									</SplitText>
								</h1>
							</TrackPose>
						}
					</PoseGroup>
				</div>
				<div style={{ position: 'absolute', top: 10, right: 25 }}>
					<Scoreboard />
				</div>
				<div style={{ position: 'absolute', width: '40%', paddingTop: 25, paddingLeft: 25, top: 0, left: 0, height: '100%' }} >
					{this.props.gameStatus.round.type === 'Speed' ?
						<div className="h-100 w-100 d-flex align-items-center justify-content-center flex-column">
							<h1 className="cabin display-1">Speed</h1>
							<h1 className="cabin display-1">Round</h1>
						</div>
						:
						<React.Fragment>
							<ThemeHints />
							<TrackDisplay />
						</React.Fragment>
					}
				</div>
				<div style={{ position: 'absolute', top: 150, left: 0, right: 10, bottom: 0, marginLeft: 'auto', width: '57%', overflow: 'hidden' }}>
					<TrackHints />
					<div style={{ height: 100 }}></div>
					<ArtistHints />
				</div>
				<div style={{
					position: 'absolute',
					bottom: 25,
					left: 0,
					height: 100,
					width: '100%'
				}}>
					<PeanutGallery />
				</div>
				<PoseGroup>
					{populateAnimation(this.state.page)}
				</PoseGroup>
			</React.Fragment>


		);
	}
}

export default connect(mapStateToProps)(ReactTimeout(MainDisplay));