import * as ActionTypes from './actionTypes';
import axios from 'axios';
import { error } from '../utils/tools';
import socketIOClient from 'socket.io-client';
const socket = socketIOClient(process.env.REACT_APP_SERVER_URL);
window.console && console.log('react app server url is ', process.env.REACT_APP_SERVER_URL);



// authentication creators
const loggingIn = () => ({
	type: ActionTypes.LOGGING_IN
});

const loginSuccess = (user) => ({
	type: ActionTypes.LOGIN_SUCCESS,
	payload: user
});

export const loginFailure = (error) => ({
	type: ActionTypes.LOGIN_FAILURE,
	payload: error
});

export const login = (user) => (dispatch) => {
	dispatch(loggingIn());

	axios.post('/user/login', { username: user.username, password: user.password })
		.then(res => {
			dispatch(loginSuccess(res.data));
		})
		.catch(err => {
			error(err);
			dispatch(loginFailure(err));
		});
};

const logoutSuccess = () => ({
	type: ActionTypes.LOGOUT_SUCCESS
});

export const logout = () => (dispatch) => {
	axios.get('/user/logout')
		.then(res => {
			dispatch(logoutSuccess());
		})
		.catch(err => {
			error(err);
			dispatch(logoutSuccess());
		})
};

export const fetchAuthentication = () => (dispatch) => {
	dispatch(loggingIn());
	axios.get('/user')
		.then(res => {
			dispatch(loginSuccess(res.data));
		})
		.catch(err => {
			error(err);
			dispatch(logoutSuccess());
		});
};

// player state
export const subscribeToPlayer = () => (dispatch) => {
	socket.on('playerListener', res => {
		dispatch(updatePlayerState(res));
	});

	socket.emit('subscribeToPlayer');
};

export const unsubscribeToPlayer = () => dispatch => {
	socket.off('playerListener');
	dispatch(updatePlayerState({}));
}

export const updatePlayerState = (state) => ({
	type: ActionTypes.UPDATE_PLAYER_STATE,
	payload: state
});

// builder creators
export const addTracks = (tracks) => ({
	type: ActionTypes.ADD_TRACKS,
	payload: tracks
});

export const addThemes = (themes) => ({
	type: ActionTypes.ADD_THEMES,
	payload: themes
});

export const removeTrack = trackId => ({
	type: ActionTypes.REMOVE_TRACK,
	payload: trackId
});

export const removeAllTracks = () => ({
	type: ActionTypes.REMOVE_ALL_TRACK,
});

export const removeTheme = themeId => ({
	type: ActionTypes.REMOVE_THEME,
	payload: themeId
});

export const removeAllThemes = () => ({
	type: ActionTypes.REMOVE_ALL_THEMES,
});

export const selectActiveTrack = track => ({
	type: ActionTypes.SELECT_ACTIVE_TRACK,
	payload: track
});

export const selectActiveTheme = theme => ({
	type: ActionTypes.SELECT_ACTIVE_THEME,
	payload: theme
});

export const updateTrack = track => ({
	type: ActionTypes.UPDATE_TRACK,
	payload: track
});

export const updateTheme = theme => ({
	type: ActionTypes.UPDATE_THEME,
	payload: theme
});

// dj creators
export const setGame = game => ({
	type: ActionTypes.SET_GAME,
	payload: game
});

export const addRound = round => ({
	type: ActionTypes.ADD_ROUND,
	payload: round
});

// game subscriptions
export const startNewGame = code => dispatch => {
	socket.on(code, res => {
		dispatch(updateGameStatus(res.data));
	});

	socket.emit('startNewRoom', code);
	socket.emit('subscribeToRoom', code);
};

export const updateGameStatus = (state) => ({
	type: ActionTypes.UPDATE_GAME_STATUS,
	payload: state
});

export const subscribeToGame = (code, cb = () => null) => dispatch => {
	socket.on(code, res => {
		cb(res.data);
		if (!res.data) {
			dispatch(unsubscribeToGame(code));
		} else {
			dispatch(updateGameStatus(res.data));
		}
	});

	socket.emit('subscribeToRoom', code);
};

export const unsubscribeToGame = code => dispatch => {
	dispatch(updateGameStatus(null));
	socket.off(code);
};

export const emitGameStatus = (code, status, type) => dispatch => {
	axios.post('/game/emit-status', { code: code, data: status, type: type })
		.then(res => null)
		.catch(err => error(err));
};
