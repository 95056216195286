import React from 'react';
import { Slider, Direction } from 'react-player-controls';


const WHITE_SMOKE = '#eee'
const GRAY = '#878c88'
const RED = '#b11616'

// A colored bar that will represent the current value
const SliderBar = ({ direction, value, style }) => (
	<div
		style={Object.assign({}, {
			position: 'absolute',
			background: GRAY,
			borderRadius: 4,
		}, direction === Direction.HORIZONTAL ? {
			top: 0,
			bottom: 0,
			left: 0,
			width: `${value * 100}%`,
		} : {
					right: 0,
					bottom: 0,
					left: 0,
					height: `${value * 100}%`,
				}, style)}
	/>
)

// A handle to indicate the current value
const SliderHandle = ({ direction, value, style }) => (
	<div
		style={Object.assign({}, {
			position: 'absolute',
			width: 16,
			height: 16,
			background: RED,
			borderRadius: '100%',
			transform: 'scale(1)',
			transition: 'transform 0.2s',
			'&:hover': {
				transform: 'scale(1.3)',
			}
		}, direction === Direction.HORIZONTAL ? {
			top: 0,
			left: `${value * 100}%`,
			marginTop: -4,
			marginLeft: -8,
		} : {
					left: 0,
					bottom: `${value * 100}%`,
					marginBottom: -8,
					marginLeft: -4,
				}, style)}
	/>
)

// A composite progress bar component
const ProgressBar = ({ isEnabled, direction, value, ...props }) => (
	<Slider
		isEnabled={isEnabled}
		direction={direction}
		onChange={window.console && console.log}
		style={{
			width: direction === Direction.HORIZONTAL ? '100%' : 8,
			height: direction === Direction.HORIZONTAL ? 8 : '100%',
			borderRadius: 4,
			background: WHITE_SMOKE,
			transition: direction === Direction.HORIZONTAL ? 'width 0.1s' : 'height 0.1s',
			cursor: isEnabled === true ? 'pointer' : 'default',
		}}
		{...props}
	>
		<SliderBar direction={direction} value={value} style={{ background: isEnabled ? RED : GRAY }} />
		<SliderHandle direction={direction} value={value} style={{ background: isEnabled ? RED : GRAY }} />
	</Slider>
)


const PlayerSlider = (props) => {
	return (
		<ProgressBar
			isEnabled
			direction={Direction.HORIZONTAL}
			value={props.progress / props.duration}
			onChange={props.onChange}
		/>
	);
}

export default PlayerSlider;